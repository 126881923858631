import {Router, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import React from 'react'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Header from '../components/Header'
import Login from '../components/Login'
import Home from '../components/management/Home'
import Overview from '../components/management/overwiew/Overview'
import Manage from '../components/management/Manage'
import Settings from '../components/management/Settings'
import Notifications from '../components/management/notifications/Notifications'
import Main from '../components/workers/Main'
import Workspace from '../components/workers/Workspace'
import Wares from '../components/warehouse/Wares'
import Inbox from '../components/management/Inbox'

export const history = createBrowserHistory()

class AppRouter extends React.Component {
    render() {
        return (
            <Router history={history}>
                <div>
                    <Header />
                    <Switch>
                        <Route path="/" component={PublicRoute(Login)} exact={true} />
                        <Route path="/management/home" component={PrivateRoute(Home, ["management"])} />
                        <Route path="/management/overview" component={PrivateRoute(Overview, ["management"])} />
                        <Route path="/management/manage" component={PrivateRoute(Manage, ["management"])} />
                        <Route path="/management/settings" component={PrivateRoute(Settings, ["management"])} />
                        <Route path="/management/notifications" component={PrivateRoute(Notifications, ["management"])} />
                        <Route path="/management/inbox" component={PrivateRoute(Inbox, ["management"])} />
                        <Route path="/main" component={PrivateRoute(Main, ["management", "main"])} />
                        <Route path="/workspace" component={PrivateRoute(Workspace, ["management", "production"])} />
                        <Route path="/wares" component={PrivateRoute(Wares, ["management", "forklift"])} />
                    </Switch>
                </div>
            </Router>
        )
    }
}
export default AppRouter;