import React from 'react'
import {history} from '../routers/AppRouter'
import socketIOClient from 'socket.io-client'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            menuToggle: false,
            dropdown: "",
            notify: false
        }

        let path = history.location.pathname
        // console.log(path.split("/")[1])
        const noHeader = ['/']

        if (path.split("/")[1] === "management") this.state = {render: 'management'}
        else this.state = {render: null}
        
        history.listen(location => {
            let path = location.pathname
            if (path.split("/")[1] === "management") this.setState({render: 'management'})
            else this.setState({render: null})
        })
    }

    handleDot = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/feedback/update`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({notify: data.notify})
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        this.handleDot()
        this.socket.on("check_notify", this.handleDot)
    }

    componentWillUnmount() {
        this.socket.off("check_notify", this.handleDot)
    }

    route = e => {
        e.preventDefault()
        history.push(`/management/${e.target.id}`)
        this.setState({menuToggle: false})
    }

    routeDD = e => {
        e.preventDefault()
        history.push(`/management/${e.target.getAttribute("parent")}?view=${e.target.id}`)
        this.setState({menuToggle: false})
    }
    
    logout = e => {
        e.preventDefault()
        fetch(`${process.env.REACT_APP_SERVER_URL}/auth/logout`, {
            credentials: 'include'
        }).then(res => {
            if (res.status === 200) {
                history.push('/')
            }
        }).catch(err=>console.log(err))
    }

    onToggleMenu = e => {
        e.preventDefault()
        this.setState({menuToggle: !this.state.menuToggle})
    }

    dropdownEnter = e => {
        e.preventDefault()
        this.setState({dropdown: e.target.id})
    }

    dropdownLeave = e => {
        e.preventDefault()
        this.setState({dropdown: ""})
    }

    render() {
        let menuToggle = this.state.menuToggle
        if (this.state?.render === "management") {
            return (
                <header>
                    <nav className="nav">
                        <ul className="nav-list__left">
                            <li className="nav-list__item">
                                <a id="home" className="nav-list__link logolink" onClick={this.route}>
                                    <img className="nav-list__link--logo" src="/img/logo.png" alt="Kroonipuu" />
                                </a>
                            </li>
                            <li className="nav-list__item" id="overview" onMouseEnter={this.dropdownEnter} onMouseLeave={this.dropdownLeave}>
                                <a id="overview" className={`nav-list__link${this.state.dropdown === "overview" ? " nav-list__link-disabled" : ""}`}>
                                    <span className="nav-list__link--span">Ülevaade</span>
                                </a>
                                <div className={`nav-dropdown${this.state.dropdown === "overview" ? "" : " nav-dropdown-disabled"}`}>
                                    <a id="wares" parent="overview" onClick={this.routeDD}>Ladu</a>
                                    <a id="workers" parent="overview" onClick={this.routeDD}>Töölised</a>
                                </div>
                            </li>
                            <li className="nav-list__item" id="manage" onMouseEnter={this.dropdownEnter} onMouseLeave={this.dropdownLeave}>
                                <a id="manage" className={`nav-list__link${this.state.dropdown === "manage" ? " nav-list__link-disabled" : ""}`}>
                                    <span className="nav-list__link--span">Haldus</span>
                                </a>
                                <div className={`nav-dropdown${this.state.dropdown === "manage" ? "" : " nav-dropdown-disabled"}`}>
                                    <a id="workers" parent="manage" onClick={this.routeDD}>Töölised</a>
                                    <a id="jobs" parent="manage" onClick={this.routeDD}>Tööd</a>
                                    <a id="users" parent="manage" onClick={this.routeDD}>Kasutajad</a>
                                </div>
                            </li>
                            <li className="nav-list__item" id="settings" onMouseEnter={this.dropdownEnter} onMouseLeave={this.dropdownLeave}>
                                <a id="settings" className={`nav-list__link${this.state.dropdown === "settings" ? " nav-list__link-disabled" : ""}`} onClick={this.route}>
                                    <span className="nav-list__link--span">Sätted</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="nav-list__right">
                            {/* <li className="nav-list__item">
                                <a id="notifications" className="nav-list__link" onClick={this.route}>
                                    <span className="nav-list__link--span"><i className="fas fa-exclamation"/></span>
                                    <span className="notif-icon">13</span>
                                </a>
                            </li> */}
                            <li className="nav-list__item">
                                <a className="nav-list__link logoutbutton" id="inbox" onClick={this.route}>
                                    <span className="nav-list__link--span"><i className="far fa-envelope"/></span>
                                    {this.state.notify && <div className="notification-dot" style={{pointerEvents: 'none'}} />}
                                </a>
                            </li>
                            <li className="nav-list__item">
                                <a className="nav-list__link logoutbutton" onClick={this.logout}>
                                    <span className="nav-list__link--span"><i className="fas fa-sign-out-alt"/></span>
                                </a>
                            </li>
                            <div className="menu-btn" onClick={this.onToggleMenu}>
                                <span className={`menu-btn--burger${this.state.menuToggle ? " open" : ""}`}></span>
                            </div>
                        </ul>
                    </nav>
                    <nav className="nav-mobile">
                        <ul className={`menu${menuToggle ? " open" : ""}`}>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="overview" onClick={this.route}>Ülevaade</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="manage" onClick={this.route}>Halda</li>
                            <li className={`menu-item${menuToggle ? " open" : ""}`} id="settings" onClick={this.route}>Sätted</li>
                            <span className={menuToggle ? "open" : ""} id="inbox" onClick={this.route}><i className="far fa-envelope"/></span>
                            <span className={menuToggle ? "open" : ""} onClick={this.logout}><i className="fas fa-sign-out-alt"/></span>
                        </ul>
                    </nav>
                </header>
            )
        } else return <div/>
    }
}

export default Header