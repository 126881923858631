import langFile from './lang.json'

export const round = n => {
    return Math.round((n + Number.EPSILON) * 100) / 100
}

export const translate = (key, lang = "est", variables = {}) => {
    let translation = langFile[lang][key] || key

    Object.keys(variables).forEach((placeholder) => {
      translation = translation.replace(`{{${placeholder}}}`, variables[placeholder])
    })

    return translation
}
/*

translate("aaaaaaaa", workers[_id]?.language)

*/