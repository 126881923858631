import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {history} from '../routers/AppRouter'

const PrivateRoute = (Component, allowedUserTypes) => {
    return class extends React.Component {
        constructor() {
            super()
            this.state = {
                loading: true,
                redirect: false
            }
        }

        componentDidMount() {
            fetch(`${process.env.REACT_APP_SERVER_URL}/checkToken`, {
                credentials: 'include'
            })
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    let type = data.accountType
                    if (!type) {
                        history.push('/')
                    } else {
                        if (allowedUserTypes?.includes(type)) {
                            this.setState({ loading: false })
                        } else {
                            if (type === "management") {
                                history.push('/management/home')
                            } else if (type === "main") {
                                history.push('/main')
                            } else if (type === "forklift") {
                                history.push('/wares')
                            } else if (type === "production") {
                                history.push('/workspace')
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false, redirect: true })
                })     
        }

        render() {
            const {loading, redirect} = this.state
            if (loading) return <div style={{backgroundColor: "#eee", height: "100vh"}}><div></div></div>
            if (redirect) return <Redirect to="/" />
            return <Component {...this.props} />
        }
    }
}

export default PrivateRoute