import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.months = {
            est: ["Jaanuar", "Veebruar", "Märts", "Aprill", "Mai", "Juuni", "Juuli", "August", "September", "Oktoober", "November", "Detsember"]
        }

        this.state = {
            view: "notes",
            newNotes: {
                features: [],
                fixes: []
            },
            notes: []
        }

        this.zebraPrinter = null
    }

    triggerChange = e => {
        if (e.key === "F9") {
            this.setState({view: "new"})
        }
    }

    componentDidMount() {
        window.removeEventListener('keydown', this.triggerChange)
        window.addEventListener('keydown', this.triggerChange)

        fetch(`${process.env.REACT_APP_SERVER_URL}/patchnotes/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(notes => {
            this.setState({notes})
        }).catch(err => {
            console.log(err)
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.triggerChange)
    }

    addField = e => {
        e.preventDefault()
        this.setState({newNotes: {...this.state.newNotes, [e.target.id]: [...this.state.newNotes[e.target.id], ""]}})
    }

    removeField = e => {
        e.preventDefault()
        this.setState({
            newNotes: {...this.state.newNotes, [e.target.id]: this.state.newNotes[e.target.id].filter((_, i) => i !== this.state.newNotes[e.target.id].length-1)}
        })
    }
    
    submitChanges = e => {
        e.preventDefault()

        fetch(`${process.env.REACT_APP_SERVER_URL}/patchnotes/set`, {
            method: 'POST',
            body: JSON.stringify({...this.state.newNotes}),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            if (res.ok) {
                this.setState({view: "notes"})
            } else {
                const error = new Error(res.error)
                throw error
            }
        }).catch(err => {
            console.log(err)
        })
    }

    onChangeFeatures = e => {
        e.preventDefault()
        const arr = [...this.state.newNotes.features]
        arr[e.target.id] = e.target.value
        this.setState({newNotes: {...this.state.newNotes, features: arr}})
    }

    onChangeFixes = e => {
        e.preventDefault()
        const arr = [...this.state.newNotes.fixes]
        arr[e.target.id] = e.target.value
        this.setState({newNotes: {...this.state.newNotes, fixes: arr}})
    }

    render() {
        let {view, notes, newNotes} = this.state

        return (
            <main>
                <div className="home">
                    {view === "new" ? <div className="new">
                        <a className="submit" onClick={this.submitChanges}>Saada</a>
                        <div className="sub">
                            <h1 className="sub-heading">Uued funktsioonid</h1>
                            {newNotes.features.map((feat, i) => {
                                return <div key={i}>
                                    <input id={i} value={feat} onChange={this.onChangeFeatures} />
                                    {i === newNotes.features.length-1 && <span className="remove" id="features" onClick={this.removeField}>&times;</span>}
                                </div>
                            })}
                            <a id="features" onClick={this.addField}><i className="fas fa-plus" /></a>
                        </div>
                        <div className="sub">
                            <h1 className="sub-heading">Parandused</h1>
                            {newNotes.fixes.map((fix, i) => {
                                return <div key={i}>
                                    <input id={i} value={fix} onChange={this.onChangeFixes} />
                                    {i === newNotes.fixes.length-1 && <span className="remove" id="fixes" onClick={this.removeField}>&times;</span>}
                                </div>
                            })}
                            <a id="fixes" onClick={this.addField}><i className="fas fa-plus" /></a>
                        </div>
                    </div> :
                    <div className="notes">
                        {notes.slice().reverse().map(note => {
                            let date = moment(note.createdAt).format("YYYY-MM-DD")
                            let dateString = `${Number(date.slice(8,10))}. ${this.months.est[Number(date.slice(5,7))-1]} ${date.slice(0,4)}`
                            return <ul className="note">
                                <h1>{dateString}</h1>
                                <h2>Uued funktsioonid</h2>
                                {note.features.map((feat, i) => {
                                    return <li key={i}>{feat}</li>
                                })}
                                <h2>Parandused</h2>
                                {note.fixes.map((fix, i) => {
                                    return <li key={i}>{fix}</li>
                                })}
                            </ul>
                        })}
                    </div>}
                </div>
            </main>
        )
    }
}

export default Home