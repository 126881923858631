import React from 'react'
import moment from 'moment'
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'

class Workers extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.state = {
            workersView: "main",
            timeToggle: false,
            filters: {
                active: false,
                moment: moment(),
                name: ""
            }
        }

        /*
        EXAMPLE
        this.props.fetchEmployees(startTime, endTime)
        
        */
    }

    componentDidMount() {
        this.props.socket.on("get_employee", this.props.getEmployee)
        this.props.socket.on("change_employee", this.props.changeEmployee)
        this.props.fetchEmployees(moment().startOf("month").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))

        this.update = setInterval(() => {
            this.props.updateTime()
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.update)

        this.props.socket.off("get_employee", this.props.getEmployee)
        this.props.socket.off("change_employee", this.props.changeEmployee)
    }

    parseTimeToArr = time => {
        return time?.split(":")
    }

    workersRoute = e => {
        e.preventDefault()

        this.props.fetchEmployees(moment().startOf("month").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
        this.setState({workersView: e.target.id, filters: {active: false}, timeToggle: false})
    }

    timeRoute = e => {
        e.preventDefault()

        let toggle = e.target.getAttribute("toggle") //on = aasta | off = kuu
        this.props.fetchEmployees(moment().startOf(toggle === "on" ? "year" : "month").format("YYYY-MM-DD"), moment().endOf(toggle === "on" ? "year" : "month").format("YYYY-MM-DD"))
        this.setState({timeToggle: toggle === "on" ? true : false})
    }

    onChange = e => {
        e.preventDefault()
        this.setState({filters: {...this.state.filters, [e.target.id]: e.target.value}})
    }

    changeTime = e => {
        e.preventDefault()
        let unit = e.target.getAttribute("unit")
        let date = e.target.id === "inc" ? moment(this.state.filters.moment).add(1, unit) : e.target.id === "dec" ? moment(this.state.filters.moment).subtract(1, unit) : this.state.filters.moment
        this.setState({filters: {...this.state.filters, moment: date}})
        this.props.fetchEmployees(date.startOf(unit).format("YYYY-MM-DD"), date.endOf(unit).format("YYYY-MM-DD"))
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({filters: {...this.state.filters, active: !this.state.filters.active}})
    }

    openReport = e => {
        e.preventDefault()
        this.props.openReport(e.target.id)
    }

    exportCSV = e => {
        e.preventDefault()

        let timeToggle = this.state.timeToggle
        let wb = new ExcelJS.Workbook()
        
        if (timeToggle) {
            let ws = wb.addWorksheet(`Aasta aruanne ${moment(this.state.filters.moment).format("YYYY")}.xlsx`)
            ws.columns = [
                {header: "Nimi", key: "name", width: 20},
                {header: "Jaanuar", key: "january", width: 12},
                {header: "Veebruar", key: "february", width: 12},
                {header: "Märts", key: "march", width: 12},
                {header: "Aprill", key: "april", width: 12},
                {header: "Mai", key: "may", width: 12},
                {header: "Juuni", key: "june", width: 12},
                {header: "Juuli", key: "july", width: 12},
                {header: "August", key: "august", width: 12},
                {header: "September", key: "september", width: 12},
                {header: "Oktoober", key: "october", width: 12},
                {header: "November", key: "november", width: 12},
                {header: "Detsember", key: "december", width: 12},
                {header: "Kokku", key: "sum", width: 12}
            ]

            ws.getRow(1).font = {bold: true}
            ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

            Object.keys(this.props.workers).forEach((worker, i) => {
                let {firstName, lastName, logs} = this.props.workers[worker]
                let sum = 0

                let fullName = firstName + " " + lastName
                if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                    
                Array(13).fill(0,0,13).forEach((key, j) => {
                    let seconds = 0
                    let limited = []
                    let timesArr = []
                    let minutes = 0
                    let secondss = 0
                    let hours = 0
                    if (j !== 12) Object.keys(this.props.workers[worker]?.logs || {}).forEach(day => {
                        if (day < moment(this.state.filters.moment).startOf('year').add(j, 'months').startOf('month').format("YYYY-MM-DD") || day > moment(this.state.filters.moment).startOf('year').add(j, 'months').endOf('month').format("YYYY-MM-DD")) return
                        let times = this.props.workers[worker].logs[day].times
                        if (times?.[0]?.split(":")[0] === "end") {
                            times.forEach((key, i) => {
                                if (i === 0) {
                                    seconds += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                    limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                }
                                if (times[i*2+1]) {
                                    let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    if (times[i*2+2]) {
                                        let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                        seconds += b.diff(a, 'seconds')
                                        timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                        timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                    } else {
                                        if (day !== moment().format("YYYY-MM-DD")) {
                                            let b = j === 0 ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                            seconds += b.diff(a, 'seconds')
                                            limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                        }
                                    }
                                }
                                if (i+1===times.length) {
                                    minutes = Math.floor(seconds / 60)
                                    secondss = seconds%60
                                    hours = Math.floor(minutes/60)
                                    minutes = minutes%60
                                }
                            })
                        } else if (times?.[0]?.split(":")[0] === "start") {
                            times.forEach((key, i) => {
                                if (i === 0) {
                                    limited.push(0)
                                }
                                if (times[i*2]) {
                                    let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    if (times[i*2+1]) {
                                        let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                        seconds += b.diff(a, 'seconds')
                                        timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                        timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                    } else {
                                        if (day !== moment().format("YYYY-MM-DD")) {
                                            let b = j === 0 ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                            seconds += b.diff(a, 'seconds')
                                            limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                        }
                                    }
                                }
                                if (i+1===times.length) {
                                    minutes = Math.floor(seconds / 60)
                                    secondss = seconds%60
                                    hours = Math.floor(minutes/60)
                                    minutes = minutes%60
                                }
                            })
                        }
                    })
                    sum += seconds
                    if (j === 12) {
                        let minutesSum = Math.floor(sum / 60)
                        sum = sum%60
                        let hoursSum = Math.floor(minutesSum/60)
                        minutesSum = minutesSum%60
                        ws.getCell(i+2, 1).value = fullName
                        ws.getCell(i+2, 14).value = `${hoursSum < 10 ? "0" + hoursSum : hoursSum}.${minutesSum < 10 ? "0" + minutesSum : minutesSum}.${sum < 10 ? "0" + sum : sum}`
                    } else ws.getCell(i+2, j+2).value = ((moment(this.state.filters.moment).format("YYYY") < moment().format("YYYY") || (moment(this.state.filters.moment).format("YYYY") === moment().format("YYYY") && Number(moment().format("M")) >= j+1)) && `${hours < 10 ? "0" + hours : hours}.${minutes < 10 ? "0" + minutes : minutes}.${secondss < 10 ? "0" + secondss : secondss}` !== "00.00.00") ? `${hours < 10 ? "0" + hours : hours}.${minutes < 10 ? "0" + minutes : minutes}.${secondss < 10 ? "0" + secondss : secondss}` : ""        
                })
            })

            ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                ws.getRow(rowNumber).alignment = {horizontal: 'center'}
            })
        
            wb.xlsx.writeBuffer().then(buffer => {
                FileSaver.saveAs(new Blob([buffer]), `Aasta aruanne ${moment(this.state.filters.moment).format("YYYY")}.xlsx`)
            }).catch(err => console.log(err))
        } else {
            let ws = wb.addWorksheet(`Kuu aruanne ${moment(this.state.filters.moment).format("MM-YYYY")}.xlsx`)

            ws.columns = [{header: "Nimi", key: "name", width: 20}]

            ws.getRow(1).font = {bold: true}
            ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

            Array(moment(this.state.filters.moment).daysInMonth()+1).fill(0,0,moment(this.state.filters.moment).daysInMonth()+1).forEach((key, i) => {
                if (i === moment(this.state.filters.moment).daysInMonth()) {
                    ws.getCell(1, i+2).value = "Kokku"
                } else ws.getCell(1, i+2).value = i+1
            })

            Object.keys(this.props.workers).forEach((worker, i) => {
                let {firstName, lastName, logs} = this.props.workers[worker]
                let sum = 0

                let fullName = firstName + " " + lastName
                if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return

                Array(moment(this.state.filters.moment).daysInMonth()+1).fill(0,0,moment(this.state.filters.moment).daysInMonth()+1).forEach((key, j) => {
                    let day = moment(this.state.filters.moment).startOf('month').add(j, 'days')
                    let times = this.props.workers[worker]?.logs?.[day.format("YYYY-MM-DD")]?.times
                    let seconds = 0
                    let minutes = 0
                    let hours = 0
                    let displayedTime = ""

                    let limited = []
                    let timesArr = []

                    if (times?.[0]?.split(":")[0] === "end") {
                        times.forEach((key, i) => {
                            if (i === 0) {
                                seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                            }
                            if (times[i*2+1]) {
                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                if (times[i*2+2]) {
                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    seconds += b.diff(a, 'seconds')
                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                    timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                } else {
                                    if (day.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                                        let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                        seconds += b.diff(a, 'seconds')
                                        limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                    }
                                }
                            }
                            if (i+1===times.length) {
                                sum += seconds
                                minutes = Math.floor(seconds / 60)
                                seconds = seconds%60
                                hours = Math.floor(minutes/60)
                                minutes = minutes%60

                                if (minutes === 59 && seconds >= 30) displayedTime = `${(hours+1) < 10 ? "0" + (hours+1) : hours+1}:00`
                                else if (seconds >= 30) displayedTime = `${hours < 10 ? "0" + hours : hours}:${(minutes+1) < 10 ? "0" + (minutes+1) : minutes+1}`
                                else displayedTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
                            }
                        })
                    } else if (times?.[0]?.split(":")[0] === "start") {
                        times.forEach((key, i) => {
                            if (i === 0) {
                                limited.push(0)
                            }
                            if (times[i*2]) {
                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                if (times[i*2+1]) {
                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                    seconds += b.diff(a, 'seconds')
                                    timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                } else {
                                    if (day.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                                        let b = /*j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : */moment(day).endOf('day')
                                        seconds += b.diff(a, 'seconds')
                                        limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                    }
                                }
                            }
                            if (i+1===times.length) {
                                sum += seconds
                                minutes = Math.floor(seconds / 60)
                                seconds = seconds%60
                                hours = Math.floor(minutes/60)
                                minutes = minutes%60

                                if (minutes === 59 && seconds >= 30) displayedTime = `${(hours+1) < 10 ? "0" + (hours+1) : hours+1}:00`
                                else if (seconds >= 30) displayedTime = `${hours < 10 ? "0" + hours : hours}:${(minutes+1) < 10 ? "0" + (minutes+1) : minutes+1}`
                                else displayedTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
                            }
                        })
                    }
                    if (j === moment(this.state.filters.moment).daysInMonth()) {
                        let minutesSum = Math.floor(sum / 60)
                        sum = sum%60
                        let hoursSum = Math.floor(minutesSum/60)
                        minutesSum = minutesSum%60
                        ws.getCell(i+2, 1).value = fullName
                        ws.getCell(i+2, j+2).value = `${hoursSum < 10 ? "0" + hoursSum : hoursSum}.${minutesSum < 10 ? "0" + minutesSum : minutesSum}`
                    } else ws.getCell(i+2, j+2).value = displayedTime
                })
            })

            ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                ws.getRow(rowNumber).alignment = {horizontal: 'center'}
            })
        
            wb.xlsx.writeBuffer().then(buffer => {
                FileSaver.saveAs(new Blob([buffer]), `Kuu aruanne ${moment(this.state.filters.moment).format("MM-YYYY")}.xlsx`)
            }).catch(err => console.log(err))
        }
    }

    render() {
        return (
            <div className="overview-workers">
                <div className={`header${this.state.filters.active ? " header-filters--active" : ""}`}>
                    <a className={`linkbutton${this.state.filters.active ? " linkbutton--active" : ""}`} onClick={this.toggleFilters}><i className="fas fa-angle-right"/> Filtreeri</a> 
                    <div className="header-nav">
                        <a className={`header-nav--button${this.state.workersView === "main" ? " header-nav--button--active": ""}`} id="main" onClick={this.workersRoute}>Emaarvuti</a>
                        <a className={`header-nav--button${this.state.workersView === "summary" ? " header-nav--button--active": ""}`} id="summary" onClick={this.workersRoute}>Aruanne</a>
                    </div>
                    {this.state.workersView === "summary" &&
                    <div className="archive-toggles">
                        <a className={this.state.timeToggle ? "" : "archive-toggles--active"} toggle="off" onClick={this.timeRoute}>Kuu</a>
                        <a className={this.state.timeToggle ? "archive-toggles--active" : ""} toggle="on" onClick={this.timeRoute}>Aasta</a>
                    </div>}
                    {this.state.workersView === "summary" && <a className="exportbutton" onClick={this.exportCSV}><i className="fas fa-file-export"></i></a>}
                    <div className="workers-filters">
                        <div className="filter">
                            <h1>Nimi</h1>
                            <input type="text" id="name" value={this.state.filters.name || ""} onChange={this.onChange} />
                        </div>
                        <div className="filter">
                            <h1>Tööliik</h1>
                            <select id="isHourly" value={this.state.filters.isHourly || ""} onChange={this.onChange}>
                                <option value=""></option>
                                <option value="hourly">Tunnitöö</option>
                                <option value="piece">Tükitöö</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="overview-content1">
                    {this.state.workersView === "main" &&
                    <div className="table">
                        <div className="row row-sticky">
                            <h1 className="name name-header">Nimi</h1>
                            <h1 className="duration duration-header">Tööl viibitud aeg</h1>
                            <div className="times">
                                <h1 className="timeheader">Sisse-välja registreerimise ajad</h1>
                            </div>
                        </div>
                        {Object.keys(this.props.workers).sort((a, b) => {
                            if(this.props.workers[a].firstName.toLowerCase() < this.props.workers[b].firstName.toLowerCase()) return -1
                            if(this.props.workers[a].firstName.toLowerCase() > this.props.workers[b].firstName.toLowerCase()) return 1
                            return 0
                        }).map(key => {
                            let {firstName, lastName, logs, checkedIn, isHourly} = this.props.workers[key]
                            let fullName = firstName + " " + lastName
                            if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                            if (this.state.filters.isHourly) {
                                if (isHourly === undefined) return
                                let a = this.state.filters.isHourly
                                if (a === "hourly" && !isHourly) return
                                if (a === "piece" && isHourly) return
                            }

                            let currentDate = moment().format("YYYY-MM-DD")
                            let datesArr = Object.keys(logs)
                            let yesterdayLast = datesArr[datesArr.length-1] === currentDate ? logs[datesArr[datesArr.length-2]]?.times?.[logs[datesArr[datesArr.length-2]]?.times?.length-1] : logs[datesArr[datesArr.length-1]]?.times?.[logs[datesArr[datesArr.length-1]]?.times?.length-1]
                            let yl = this.parseTimeToArr(yesterdayLast)

                            if (!logs?.[currentDate]?.times?.length && (!yesterdayLast || yl[0] === "end")) return
                            let seconds = this.props.workers[key].seconds
                            let latest = this.parseTimeToArr(logs?.[currentDate]?.times?.[logs[currentDate].times.length-1])

                            if (latest?.[0] === "start") {
                                seconds += moment().diff(moment(`${currentDate} ${latest[1]}:${latest[2]}:${latest[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                            } else {
                                if (yl?.[0] === "start" && latest?.[0] !== "end") {
                                    seconds += moment().diff(moment(`${datesArr[datesArr.length-1] === currentDate ? datesArr[datesArr.length-2] : datesArr[datesArr.length-1]} ${yl[1]}:${yl[2]}:${yl[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                                }
                            }

                            let minutes = Math.floor(seconds / 60)
                            seconds = seconds%60
                            let hours = Math.floor(minutes/60)
                            minutes = minutes%60
                            return (
                                <div className="row" key={key}>
                                    <h1 className="name name-hoverable" id={key} onClick={this.openReport}>{firstName} {lastName}</h1>
                                    <h1 className="duration">{`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</h1>
                                    <div className="times">
                                        {yesterdayLast?.split(":")[0] === "start" &&
                                            <h1 className="time">{`${yesterdayLast.split(":")[1]}:${yesterdayLast.split(":")[2]}`} - {`${logs?.[currentDate]?.times[0]?.split(":")[1] || "--"}:${logs?.[currentDate]?.times[0]?.split(":")[2] || "--"}`}</h1>
                                        }
                                        {logs[currentDate]?.times.map((key, i) => {
                                            if (yesterdayLast?.split(":")[0] === "start") {
                                                if (!logs[currentDate].times[i*2+1]) return
                                                return <h1 className="time" key={i}>{`${logs[currentDate].times[i*2+1].split(":")[1]}:${logs[currentDate].times[i*2+1].split(":")[2]}`} - {`${logs[currentDate].times[i*2+2]?.split(":")[1] || "--"}:${logs[currentDate].times[i*2+2]?.split(":")[2] || "--"}`}</h1>
                                            } else {
                                                if (!logs[currentDate].times[i*2]) return
                                                return <h1 className="time" key={i}>{`${logs[currentDate].times[i*2].split(":")[1]}:${logs[currentDate].times[i*2].split(":")[2]}`} - {`${logs[currentDate].times[i*2+1]?.split(":")[1] || "--"}:${logs[currentDate].times[i*2+1]?.split(":")[2] || "--"}`}</h1>                                                
                                            }
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div> ||
                    this.state.workersView === "summary" &&
                    <div style={{height: '100%'}}>
                        <div className="timechanger">
                            <i className="fas fa-angle-left" unit={`${this.state.timeToggle ? "year" : "month"}`} id="dec" onClick={this.changeTime} />
                            <h1>{this.state.timeToggle ? "" : `${this.props.months?.est[moment(this.state.filters.moment).format("M")-1]}, `}{moment(this.state.filters.moment).format("YYYY")}</h1>
                            <i className="fas fa-angle-right" unit={`${this.state.timeToggle ? "year" : "month"}`} id="inc" onClick={this.changeTime} />
                        </div>
                        {this.state.timeToggle ? 
                        <div className="table">
                            <div className="row">
                                <h1 className="name name-header">Nimi</h1>
                                
                                {Array(13).fill(0,0,13).map((key, i) => {
                                    if (i === 12) return <h1 key={i} className="month">Kokku</h1>
                                    return <h1 key={i} className="month">{this.props.months?.est[i]}</h1>
                                })}
                            </div>
                            {Object.keys(this.props.workers).map(worker => {
                                let {firstName, lastName, logs, isHourly} = this.props.workers[worker]
                                let sum = 0

                                let fullName = firstName + " " + lastName
                                if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                if (this.state.filters.isHourly) {
                                    if (isHourly === undefined) return
                                    let a = this.state.filters.isHourly
                                    if (a === "hourly" && !isHourly) return
                                    if (a === "piece" && isHourly) return
                                }
                                
                                return <div key={worker} className="row">
                                    <h1 className="name name-hoverable" id={worker} onClick={this.openReport}>{firstName} {lastName}</h1>
                                    {Array(13).fill(0,0,13).map((key, j) => {
                                        let seconds = 0
                                        let limited = []
                                        let timesArr = []
                                        let minutes = 0
                                        let secondss = 0
                                        let hours = 0
                                        if (j !== 12) Object.keys(this.props.workers[worker]?.logs || {}).forEach(day => {
                                            if (day < moment(this.state.filters.moment).startOf('year').add(j, 'months').startOf('month').format("YYYY-MM-DD") || day > moment(this.state.filters.moment).startOf('year').add(j, 'months').endOf('month').format("YYYY-MM-DD")) return
                                            let times = this.props.workers[worker].logs[day].times
                                            if (times?.[0]?.split(":")[0] === "end") {
                                                times.forEach((key, i) => {
                                                    if (i === 0) {
                                                        seconds += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                                        limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                                    }
                                                    if (times[i*2+1]) {
                                                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                        if (times[i*2+2]) {
                                                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                            seconds += b.diff(a, 'seconds')
                                                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                            timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                                        } else {
                                                            if (day !== moment().format("YYYY-MM-DD")) {
                                                                let b = j === 0 ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                                seconds += b.diff(a, 'seconds')
                                                                limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                            }
                                                        }
                                                    }
                                                    if (i+1===times.length) {
                                                        minutes = Math.floor(seconds / 60)
                                                        secondss = seconds%60
                                                        hours = Math.floor(minutes/60)
                                                        minutes = minutes%60
                                                    }
                                                })
                                            } else if (times?.[0]?.split(":")[0] === "start") {
                                                times.forEach((key, i) => {
                                                    if (i === 0) {
                                                        limited.push(0)
                                                    }
                                                    if (times[i*2]) {
                                                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                        if (times[i*2+1]) {
                                                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                            seconds += b.diff(a, 'seconds')
                                                            timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                        } else {
                                                            if (day !== moment().format("YYYY-MM-DD")) {
                                                                let b = j === 0 ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                                seconds += b.diff(a, 'seconds')
                                                                limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                            }
                                                        }
                                                    }
                                                    if (i+1===times.length) {
                                                        minutes = Math.floor(seconds / 60)
                                                        secondss = seconds%60
                                                        hours = Math.floor(minutes/60)
                                                        minutes = minutes%60
                                                    }
                                                })
                                            }
                                        })
                                        sum += seconds
                                        if (j === 12) {
                                            let minutesSum = Math.floor(sum / 60)
                                            sum = sum%60
                                            let hoursSum = Math.floor(minutesSum/60)
                                            minutesSum = minutesSum%60
                                            return <h1 key={j} className="month">{`${hoursSum < 10 ? "0" + hoursSum : hoursSum}:${minutesSum < 10 ? "0" + minutesSum : minutesSum}:${sum < 10 ? "0" + sum : sum}`}</h1>
                                        }
                                        return <h1 key={j} className="month">{((moment(this.state.filters.moment).format("YYYY") < moment().format("YYYY") || (moment(this.state.filters.moment).format("YYYY") === moment().format("YYYY") && Number(moment().format("M")) >= j+1)) && `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}` !== "00:00:00") ? `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}` : ""}</h1>
                                    })}
                                </div>
                            })}
                        </div> :
                        <div className="table">
                            <div className="row">
                                <h1 className="name name-header">Nimi</h1>
                                {Array(moment(this.state.filters.moment).daysInMonth()+1).fill(0,0,moment(this.state.filters.moment).daysInMonth()+1).map((key, i) => {
                                    if (i === moment(this.state.filters.moment).daysInMonth()) return <h1 key={i} className="day">Kokku</h1>
                                    return <h1 key={i} className="day">{i+1}</h1>
                                })}
                            </div>
                            {Object.keys(this.props.workers).map(worker => {
                                let {firstName, lastName, logs, isHourly} = this.props.workers[worker]
                                let sum = 0

                                let fullName = firstName + " " + lastName
                                if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                if (this.state.filters.isHourly) {
                                    if (isHourly === undefined) return
                                    let a = this.state.filters.isHourly
                                    if (a === "hourly" && !isHourly) return
                                    if (a === "piece" && isHourly) return
                                }
                                
                                return <div key={worker} className="row">
                                    <h1 className="name name-hoverable" id={worker} onClick={this.openReport}>{firstName} {lastName}</h1>
                                    {Array(moment(this.state.filters.moment).daysInMonth()+1).fill(0,0,moment(this.state.filters.moment).daysInMonth()+1).map((key, j) => {
                                        
                                        let day = moment(this.state.filters.moment).startOf('month').add(j, 'days')
                                        let times = this.props.workers[worker]?.logs?.[day.format("YYYY-MM-DD")]?.times
                                        let seconds = 0
                                        let minutes = 0
                                        let hours = 0
                                        let displayedTime = ""
            
                                        let limited = []
                                        let timesArr = []
            
                                        if (times?.[0]?.split(":")[0] === "end") {
                                            times.forEach((key, i) => {
                                                if (i === 0) {
                                                    seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                                    limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                                }
                                                if (times[i*2+1]) {
                                                    let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    if (times[i*2+2]) {
                                                        let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                        seconds += b.diff(a, 'seconds')
                                                        timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                        timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                                    } else {
                                                        if (day.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                                                            let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                            seconds += b.diff(a, 'seconds')
                                                            limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                        }
                                                    }
                                                }
                                                if (i+1===times.length) {
                                                    sum += seconds
                                                    minutes = Math.floor(seconds / 60)
                                                    seconds = seconds%60
                                                    hours = Math.floor(minutes/60)
                                                    minutes = minutes%60
                                                    if (minutes === 59 && seconds >= 30) displayedTime = `${(hours+1) < 10 ? "0" + (hours+1) : hours+1}:00`
                                                    else if (seconds >= 30) displayedTime = `${hours < 10 ? "0" + hours : hours}:${(minutes+1) < 10 ? "0" + (minutes+1) : minutes+1}`
                                                    else displayedTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
                                                }
                                            })
                                        } else if (times?.[0]?.split(":")[0] === "start") {
                                            times.forEach((key, i) => {
                                                if (i === 0) {
                                                    limited.push(0)
                                                }
                                                if (times[i*2]) {
                                                    let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    if (times[i*2+1]) {
                                                        let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                        seconds += b.diff(a, 'seconds')
                                                        timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                        timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                    } else {
                                                        if (day.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                                                            let b = /*j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") :*/moment(day).endOf('day')
                                                            seconds += b.diff(a, 'seconds')
                                                            limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                        }
                                                    }
                                                }
                                                if (i+1===times.length) {
                                                    sum += seconds
                                                    minutes = Math.floor(seconds / 60)
                                                    seconds = seconds%60
                                                    hours = Math.floor(minutes/60)
                                                    minutes = minutes%60
                                                    
                                                    if (minutes === 59 && seconds >= 30) displayedTime = `${(hours+1) < 10 ? "0" + (hours+1) : hours+1}:00`
                                                    else if (seconds >= 30) displayedTime = `${hours < 10 ? "0" + hours : hours}:${(minutes+1) < 10 ? "0" + (minutes+1) : minutes+1}`
                                                    else {
                                                        displayedTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
                                                    }
                                                }
                                            })
                                        }
                                        if (j === moment(this.state.filters.moment).daysInMonth()) {
                                            let minutesSum = Math.floor(sum / 60)
                                            sum = sum%60
                                            let hoursSum = Math.floor(minutesSum/60)
                                            minutesSum = minutesSum%60
                                            return <h1 key={j} className="day">{`${hoursSum < 10 ? "0" + hoursSum : hoursSum}:${minutesSum < 10 ? "0" + minutesSum : minutesSum}`}</h1>
                                        }
                                        return <h1 key={j} className="day">{displayedTime}</h1>
                                    })}
                                </div>
                            })}
                        </div>}
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Workers