import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'

import {history} from '../../routers/AppRouter'
import {round} from '../utils/utils'

class Manage extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        let params = new URLSearchParams(window.location.search)
        let view = params.get('view')

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.weekdays = {
            est: ["Esmaspäev", "Teisipäev", "Kolmapäev", "Neljapäev", "Reede", "Laupäev", "Pühapäev"]
        }

        this.months = {
            est: ["Jaanuar", "Veebruar", "Märts", "Aprill", "Mai", "Juuni", "Juuli", "August", "September", "Oktoober", "November", "Detsember"]
        }

        this.state = {
            view,
            modal: {
                view: '',
                date: '',
                id: '',
                data: {}
            },
            workers: {},
            users: {},
            jobs: {},
            teams: {},
            filters: {
                name: '',
                type: '',
                working: false,
                from: '',
                to: ''
            },
            time: moment().format("HH:mm:ss"),
            summary: '',
            summary2: '',
            newDriver: false,
            settings: {},
            stock: {},
            jobView: "",
            finishRestModal: null,
            hover: "",
            latestSE: {
                start: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD")
            },
            toggledAlused: [],
            logsHelp: "",
            workerVisibility: false
        }
    }

    parseTimeToArr = time => {
        return time?.split(":")
    }

    getStock = data => {
        if (data?.error || !data) return
        if (!data.length) {
            this.setState({stock: {}})
            return
        }
        data.forEach((item, i) => {
            if (i === 0) {
                this.setState({stock: {[item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            } else {
                this.setState({stock: {...this.state.stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            }
            if (i+1 === data.length) {
                this.socket.off("get_stock", this.getStock)
            }
        })
    }

    fetchEmployees = (start, end) => {
        this.setState({latestSE: {start, end}})
        this.socket.emit("employeeGet", {start, end})
    }

    getEmployee = data => {
        if (data?.error || !data) return
        data.forEach((worker, i) => {
            let seconds = 0
            let currentDate = moment().format("YYYY-MM-DD")
            let datesArr = Object.keys(worker.logs)

            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
            if (datesArr[datesArr.length-1] !== currentDate || worker.logs[datesArr[datesArr.length-1]]?.times[worker.logs[datesArr[datesArr.length-1]]?.times-1]?.split(":")[0] === "end") {
                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                return
            }
            
            if (datesArr[datesArr.length-1] === currentDate) {
                if (worker.logs[currentDate].times[0]?.split(":")[0] === "end") {
                    let a = this.parseTimeToArr(worker.logs[currentDate].times[0])
                    let b = this.parseTimeToArr(worker.logs[datesArr[datesArr.length-2]]?.times[worker.logs[datesArr[datesArr.length-2]].times.length-1])
                    seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${datesArr[datesArr.length-2]} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                    
                    worker.logs[currentDate].times.forEach((key, i) => {
                        if (i === 0) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            return
                        }
                        if (!worker.logs[currentDate].times[i*2-1]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2-1])
                            
                            seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                        }
                    })
                } else {
                    worker.logs[currentDate].times.forEach((key, i) => {
                        let a
                        let b
                        if (!worker.logs[currentDate].times[i*2]) {
                            if (i+1 === worker.logs[currentDate].times.length) {
                                this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                                if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                            }
                            return
                        }
                        
                        if (worker.logs[currentDate].times[i*2+1]) {
                            a = this.parseTimeToArr(worker.logs[currentDate].times[i*2+1])
                            b = this.parseTimeToArr(worker.logs[currentDate].times[i*2])

                            seconds += moment(`${currentDate} ${a?.[1]}:${a?.[2]}:${a?.[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(`${currentDate} ${b?.[1]}:${b?.[2]}:${b?.[3]}`, "YYYY-MM-DD HH:mm:ss"), 'seconds')
                        }

                        if (i+1 === worker.logs[currentDate].times.length) {
                            this.setState({workers: {...this.state.workers, [worker._id]: {...worker, seconds}}})
                            if (this.state.modal?.id) this.calcSummary(this.state.modal?.id)
                        }
                    })
                }
            }
        })
    }

    getTeam = data => {
        if (data?.error || !data) return
        if (data.team) data.team.forEach((team, i) => this.setState({teams: {...this.state.teams, [team._id]: {...team}}}))
        else if (data.history) {
            this.setState({teams: {...this.state.teams, [this.state.modal.id]: {...this.state.teams[this.state.modal.id], history: data.history}}})
        }
    }

    changeEmployee = () => this.socket.emit("employeeGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})
    
    changeJob = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let jobs = {}
            data.forEach((job, i) => {
                jobs = {...jobs, [job._id]: job}
                if (this.state.modal.id === job._id) this.setState({modal: {...this.state.modal, data: job}})
                if (i+1 === data.length) {
                    this.setState({jobs})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({settings: data})
        }).catch(err => {
            console.log(err)
        })

        this.socket.emit("stockGet", {})
        this.socket.on("get_stock", this.getStock)
        this.socket.emit("employeeGet", {start: this.state.latestSE.start, end: this.state.latestSE.end})
        this.socket.on("get_employee", this.getEmployee)
        this.socket.emit("teamGet")
        this.socket.on("get_team", this.getTeam)
        this.socket.on("change_employee", this.changeEmployee)
        this.socket.on("change_job", this.changeJob)
        let view = this.state.view
        if (view === 'workers') {
            this.update = setInterval(() => {
                this.setState({time: moment().format("HH:mm:ss")})
            }, 1000)
            this.setState({view, filters: {name: '', type: '', working: false, from: '', to: ''}})
        } else if (view === 'jobs') {
            clearInterval(this.update)
            fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!data.length) this.setState({view, jobView: "jobs", filters: {name: '', type: '', active: false}})
                let jobs = {}
                data.forEach((job, i) => {
                    jobs = {...jobs, [job._id]: job}
                    if (i+1 === data.length) {
                        this.setState({view, jobView: "jobs", jobs, filters: {name: '', type: '', active: false}})
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        } else if (view === 'users') {
            clearInterval(this.update)
            fetch(`${process.env.REACT_APP_SERVER_URL}/users/get`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!data.length) this.setState({view, filters: {name: '', type: ''}})
                let users = {}
                data.forEach((user, i) => {
                    users = {...users, [user._id]: user}
                    if (i+1 === data.length) {
                        this.setState({view, users, filters: {name: '', type: ''}})
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }

        this.update = setInterval(() => {
            this.setState({time: moment().format("HH:mm:ss")})
        }, 1000)
    }

    componentDidUpdate(prevProps) {
        let params = new URLSearchParams(window.location.search)
        let view = params.get('view')
        if (view !== "workers" && view !== "jobs" && view !== "users") {
            history.push("/management/manage?view=workers")
            return
        }
        if (prevProps.location.search.includes(view)) return
        if (view === 'workers') {
            this.update = setInterval(() => {
                this.setState({time: moment().format("HH:mm:ss")})
            }, 1000)
            this.setState({view, filters: {name: '', type: '', working: false, from: '', to: ''}})
        } else if (view === 'jobs') {
            clearInterval(this.update)
            fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!data.length) this.setState({view, jobView: "jobs", filters: {name: '', type: '', active: false}})
                let jobs = {}
                data.forEach((job, i) => {
                    jobs = {...jobs, [job._id]: job}
                    if (i+1 === data.length) {
                        this.setState({view, jobView: "jobs", jobs, filters: {name: '', type: '', active: false}})
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        } else if (view === 'users') {
            clearInterval(this.update)
            fetch(`${process.env.REACT_APP_SERVER_URL}/users/get`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!data.length) this.setState({view, filters: {name: '', type: ''}})
                let users = {}
                data.forEach((user, i) => {
                    users = {...users, [user._id]: user}
                    if (i+1 === data.length) {
                        this.setState({view, users, filters: {name: '', type: ''}})
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }
        // this.setState({view})
    }

    componentWillUnmount() {
        this.socket.off("get_employee", this.getEmployee)
        this.socket.off("change_employee", this.changeEmployee)
        this.socket.off("change_job", this.changeJob)
        this.socket.off("get_team", this.getTeam)
        clearInterval(this.update)
    }

    // route = e => {
    //     e.preventDefault()

    //     if (e.target.id === 'workers') {
    //         this.update = setInterval(() => {
    //             this.setState({time: moment().format("HH:mm:ss")})
    //         }, 1000)
    //         this.setState({view: e.target.id, filters: {name: '', type: '', working: false, from: '', to: ''}})
    //     } else if (e.target.id === 'jobs') {
    //         clearInterval(this.update)
    //         fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/get`, {
    //             credentials: 'include'
    //         }).then(res => {
    //             return res.json()
    //         }).then(data => {
    //             if (!data.length) this.setState({view: e.target.id, jobView: "jobs", filters: {name: '', type: '', active: false}})
    //             let jobs = {}
    //             data.forEach((job, i) => {
    //                 jobs = {...jobs, [job._id]: job}
    //                 if (i+1 === data.length) {
    //                     this.setState({view: e.target.id, jobView: "jobs", jobs, filters: {name: '', type: '', active: false}})
    //                 }
    //             })
    //         }).catch(err => {
    //             console.log(err)
    //         })
    //     } else if (e.target.id === 'users') {
    //         clearInterval(this.update)
    //         fetch(`${process.env.REACT_APP_SERVER_URL}/users/get`, {
    //             credentials: 'include'
    //         }).then(res => {
    //             return res.json()
    //         }).then(data => {
    //             if (!data.length) this.setState({view: e.target.id, filters: {name: '', type: ''}})
    //             let users = {}
    //             data.forEach((user, i) => {
    //                 users = {...users, [user._id]: user}
    //                 if (i+1 === data.length) {
    //                     this.setState({view: e.target.id, users, filters: {name: '', type: ''}})
    //                 }
    //             })
    //         }).catch(err => {
    //             console.log(err)
    //         })
    //     }
    // }

    jobRoute = e => {
        e.preventDefault()
        this.setState({jobView: e.target.id, filters: {active: false}})
    }

    exportCSV = e => {
        e.preventDefault()

        let wb = new ExcelJS.Workbook()

        if (this.state.modal?.view === "report") {
            let worker = this.state.workers[this.state.modal.id]
            const ws = wb.addWorksheet(`${worker.firstName} ${worker.lastName}`)

            ws.columns = [
                {header: "Päev", key: "day", width: 15},
                {header: "Tööl viibitud aeg", key: "duration", width: 20},
                {header: `Sisse-välja registreerimise ajad - ${worker.firstName} ${worker.lastName}`, key: "times", width: 15},
                {width: 15},
                {width: 15},
                {width: 15},
                {width: 15}
                // {header: `${worker.firstName} ${worker.lastName}`, key: "name", width: 15}
            ]

            ws.mergeCells("C1:G1")
            ws.getRow(1).font = {bold: true}
            ws.views = [{state: 'frozen', xSplit: 2, ySplit: 1}]

            Array(((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')+1 : 63).fill(0,0,((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')+1 : 63).forEach((key, j) => {
                if ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) {
                    if (j === moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days')) {
                        ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
        
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), `${worker.firstName} ${worker.lastName}.xlsx`)
                        }).catch(err => console.log(err))
                        return
                    }
                } else {
                    if (j === 62) {
                        ws.eachRow({includeEmpty: false}, (row, rowNumber) => {
                            ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                        })
        
                        wb.xlsx.writeBuffer().then(buffer => {
                            FileSaver.saveAs(new Blob([buffer]), `${worker.firstName} ${worker.lastName}.xlsx`)
                        }).catch(err => console.log(err))
                        return
                    }
                }

                if ((!this.state.filters.to || !this.state.filters.from) || this.state.filters.to < this.state.filters.from) {
                    if (moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().startOf('month').format("YYYY-MM-DD") && moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")) return
                }
                
                let day = ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).subtract(j, 'days') : moment().subtract(j, 'days') 
                let times = worker?.logs?.[day.format("YYYY-MM-DD")]?.times
                let seconds = 0
                let minutes = 0
                let hours = 0

                let limited = []
                let timesArr = []

                if (times?.[0]?.split(":")[0] === "end") {
                    times.forEach((key, i) => {
                        if (i === 0) {
                            seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                            limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                        }
                        if (times[i*2+1]) {
                            let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            if (times[i*2+2]) {
                                let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                seconds += b.diff(a, 'seconds')
                                timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                            } else {
                                let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            }
                        }
                        if (i+1===times.length) {
                            minutes = Math.floor(seconds / 60)
                            seconds = seconds%60
                            hours = Math.floor(minutes/60)
                            minutes = minutes%60
                        }
                    })
                } else if (times?.[0]?.split(":")[0] === "start") {
                    times.forEach((key, i) => {
                        if (i === 0) {
                            limited.push(0)
                        }
                        if (times[i*2]) {
                            let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            if (times[i*2+1]) {
                                let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                seconds += b.diff(a, 'seconds')
                                timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            } else {
                                let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            }
                        }
                        if (i+1===times.length) {
                            minutes = Math.floor(seconds / 60)
                            seconds = seconds%60
                            hours = Math.floor(minutes/60)
                            minutes = minutes%60
                        }
                    })
                }

                ws.addRow({day: `${day.format("DD")}. ${this.months.est[day.format('M')-1]}`, duration: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`})
                
                if (limited[0]) ws.getCell(j+2, 3).value = `--:-- - ${limited[0]}`
                timesArr.forEach((key, i) => {
                    if (!timesArr[i*2]) return
                    if (limited[0]) ws.getCell(j+2, i+4).value = `${timesArr[i*2]} - ${timesArr[i*2+1]}`
                    else ws.getCell(j+2, i+3).value = `${timesArr[i*2]} - ${timesArr[i*2+1]}`
                })
                if (limited[1]) ws.getCell(j+2, timesArr.length/2+3).value = `${limited[1]} - --:--`
            })
        } else if (this.state.modal?.view === "workerdetails") {
            const ws = wb.addWorksheet(`${this.state.workers[this.state.modal.id].firstName} ${this.state.workers[this.state.modal.id].lastName} ${this.state.modal.year || moment().format("YYYY")} ${this.months.est[this.state.modal.month || moment().format("M")-1]}`)

            let columns = [
                {header: "", key: "date", width: 20}
            ]

            this.state.modal.items.forEach((key, i) => {
                columns.push({header: this.state.stock[key]?.name, key, width: 20})
            })
            ws.columns = [...columns, {header: "Tunnitöö", key: "hourly", width: 20}]

            ws.views = [{state: 'frozen', xSplit: 1, ySplit: 1}]

            let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
            let sum = []
            let hsum = 0
            Array(moment(`${month}`).daysInMonth()+1).fill(0).forEach((key, i) => {
                if (i === moment(`${month}`).daysInMonth()) {
                    sum.push(hsum)
                    let sumrow = {date: "Kokku"}
                    sum.forEach((key, i) => {
                        sumrow = {...sumrow, [this.state.modal.items[i] || "hourly"]: sum[i]}
                    })
                    ws.addRow(sumrow)
                    ws.eachRow({includeEmpty: true}, (row, rowNumber) => {
                        ws.getRow(rowNumber).alignment = {horizontal: 'center'}
                    })
                    
                    wb.xlsx.writeBuffer().then(buffer => {
                        FileSaver.saveAs(new Blob([buffer]), `${this.state.workers[this.state.modal.id].firstName} ${this.state.workers[this.state.modal.id].lastName}  ${this.state.modal.year || moment().format("YYYY")} ${this.months.est[this.state.modal.month || moment().format("M")-1]}.xlsx`)
                    }).catch(err => console.log(err))
                    
                    return
                }
                let today = moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")
                hsum += this.state.workers[this.state.modal.id]?.hourly?.[today]?.hours || 0
                let addrow = {date: moment(today).format("DD/MM/YYYY")}
                this.state.modal.items.forEach((key, i) => {
                    if (!sum[i]) sum[i] = 0
                    sum[i] += this.state.workers[this.state.modal.id].work[today]?.[key] || 0
                    addrow = {...addrow, [key]: this.state.workers[this.state.modal.id].work[today]?.[key] || 0}
                })
                ws.addRow({...addrow, hourly: this.state.workers[this.state.modal.id]?.hourly?.[today]?.hours || 0})
                
                // return <div className="workerdetails-row" key={today}>
                //     <h1 className="sticky">{moment(today).format("DD/MM/YYYY")}</h1>
                //     {this.state.modal.items.forEach((key, i) => {
                //         if (!sum[i]) sum[i] = 0
                //         sum[i] += this.state.workers[this.state.modal.id].work[today]?.[key] || 0
                //         return <h1>{this.state.workers[this.state.modal.id].work[today]?.[key] || 0}</h1>
                //     })}
                //     <h1 id={today} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>{this.state.workers[this.state.modal.id]?.hourly?.[today]?.hours}</h1>
                //     {this.state.workers[this.state.modal.id]?.hourly?.[today]?.comment && <div className={`hourlycomment${Number(moment(today).format("DD")) > 15 ? " hourlycomment-bottom": ""}`} style={{display: this.state.hover === today ? 'block' : 'none'}}>{this.state.workers[this.state.modal.id]?.hourly?.[today]?.comment}</div>}
                // </div>
            })
        } 
    }

    calcSummary = id => {
        let seconds = 0
        let limited = []
        let timesArr = []
        let seconds2 = 0
        let limited2 = []
        let timesArr2 = []
        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().startOf('month').format("YYYY-MM-DD") || day > moment().endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds += b.diff(a, 'seconds')
                            timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            if (day !== moment().format("YYYY-MM-DD")) {
                                let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                seconds += b.diff(a, 'seconds')
                                limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            }
                        }
                    }
                    if (i+1===times.length) {
                        let minutes = Math.floor(seconds / 60)
                        let secondss = seconds%60
                        let hours = Math.floor(minutes/60)
                        minutes = minutes%60

                        this.setState({summary: `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${secondss < 10 ? "0" + secondss : secondss}`})
                    }
                })
            }
        })

        Object.keys(this.state.workers[id]?.logs || {}).forEach(day => {
            if (day < moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD") || day > moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")) return
            let times = this.state.workers[id].logs[day].times
            if (times?.[0]?.split(":")[0] === "end") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        seconds2 += moment(`${day} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                        limited2.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                    }
                    if (times[i*2+1]) {
                        let a = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+2]) {
                            let b = moment(`${day} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            } else if (times?.[0]?.split(":")[0] === "start") {
                times.forEach((key, i) => {
                    if (i === 0) {
                        limited2.push(0)
                    }
                    if (times[i*2]) {
                        let a = moment(`${day} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                        if (times[i*2+1]) {
                            let b = moment(`${day} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                            seconds2 += b.diff(a, 'seconds')
                            timesArr2.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                            timesArr2.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                        } else {
                            let b = moment(day).format("DD") === "01" ? moment(`${day} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                            seconds2 += b.diff(a, 'seconds')
                            limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                        }
                    }
                    if (i+1===times.length) {
                        let minutes2 = Math.floor(seconds2 / 60)
                        let seconds2s = seconds2%60
                        let hours2 = Math.floor(minutes2/60)
                        minutes2 = minutes2%60
                        
                        this.setState({summary2: `${hours2 < 10 ? "0" + hours2 : hours2}:${minutes2 < 10 ? "0" + minutes2 : minutes2}:${seconds2s < 10 ? "0" + seconds2s : seconds2s}`})
                    }
                })
            }
        })
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({filters: {...this.state.filters, active: !this.state.filters.active}})
    }

    toggleModal = e => {
        e.preventDefault()

        let id = e.target.id

        if (e.target.className === "details") {
            this.setState({modal: {view: 'worker', id}})
        } else if (e.target.className === "details teamdetails") {
            this.setState({modal: {view: 'team', id}})
        } else if (e.target.className === "report") {
            this.setState({modal: {view: 'report', id}})
            if (this.state.view === "workers") this.calcSummary(id)
            if (this.state.view === "jobs") this.socket.emit("teamHistory", {start: moment().subtract(1, 'month').format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD"), team: id})
        } else if (e.target.className === "add-item") {
            this.setState({modal: {...this.state.modal, addItem: {type: id, filter: "", countModal: false, temp: e.target.id === "workers" ? (this.state.modal.data?.workers || []) : []}, selected: 'left'}})
        } else if (e.target.className === "row" || e.target.className === "row row-finished") {
            this.setState({modal: {view: 'job', id, data: this.state.jobs[id]}})
        } else if (e.target.id === "changeteam") {
            this.setState({modal: {...this.state.modal, changeTeam: {id: e.target.getAttribute("dateID"), team: this.state.teams[this.state.modal.id].history[e.target.getAttribute("dateID")].team}}})
        } else if (e.target.id === "changeteam-toggle") {
            this.setState({modal: {...this.state.modal, changeTeam: {...this.state.modal.changeTeam, toggled: !this.state.modal.changeTeam.toggled}}})
        }
    }

    toggleModalWorkerdetails = e => {
        e.preventDefault()

        if (this.state.modal.view === 'workerdetails') {
            this.setState({modal: {view: '', date: '', id: '', data: {}}})
            return
        }

        let worker = e.target.id
        let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
        let items = []
        Array(moment(month).daysInMonth()).fill(0).forEach((key, i) => {
            Object.keys(this.state.workers[worker]?.work?.[moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")] || {}).forEach(key => {
                if (!items.includes(key)) items.push(key)
            })
        })

        this.setState({modal: {view: 'workerdetails', id: worker, items}})
    }

    toggleTeamModal = e => {
        e.preventDefault()

        let id = e.target.id

        if (e.target.className === "details") {
            this.setState({modal: {view: 'teamdetails', id}})
        } else if (e.target.className === "report") {
            this.setState({modal: {view: 'teamreport', id}})
        }
    }

    mouseOver = e => {
        e.preventDefault()
        this.setState({hover: e.target.id})
    }

    mouseOut = e => {
        e.preventDefault()
        this.setState({hover: ""})
    }

    onChangeWDModal = e => {
        e.preventDefault()
        let worker = this.state.modal.id
        let y = e.target.className === "year" ? e.target.value : undefined
        let m = e.target.className === "month" ? e.target.value : undefined
        let month = `${y || this.state.modal.year || moment().format("YYYY")}-${Number(m)+1 || Number(this.state.modal.month)+1 || moment().format("M")}`
        let items = []
        Array(moment(month).daysInMonth()).fill(0).forEach((key, i) => {
            Object.keys(this.state.workers[worker]?.work?.[moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")] || {}).forEach(key => {
                if (!items.includes(key)) items.push(key)
            })
        })

        this.setState({modal: {...this.state.modal, items, [e.target.className]: e.target.value}})
    }

    onChangeAddItemFilter = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, addItem: {...this.state.modal.addItem, filter: e.target.value}}})
    }

    changeAddItemType = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, selected: e.target.id}})
    }

    openTime = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, date: e.target.getAttribute("date"), data: {from: e.target.getAttribute("from"), to: e.target.getAttribute("to")}}})
    }

    closeModal = e => {
        e.preventDefault()

        if (e.target.id === "teammodal") {
            this.setState({modal: {view: '', date: '', id: '', data: {}}, teams: {...this.state.teams, [this.state.modal.id]: {...this.state.teams[this.state.modal.id], history: {}}}})
        } else if (this.state.modal.addItem?.countModal) {
            this.setState({modal: {...this.state.modal, addItem: {...this.state.modal.addItem, countModal: false}}})
        } else if (e.target.getAttribute("modal") === "datemodal") {
            this.setState({modal: {...this.state.modal, view: 'report', date: '', data: {}}})
        } else if (this.state.modal.addItem) {
            this.setState({modal: {...this.state.modal, addItem: null}})
        } else if (e.target.id === "membersmodal") {
            this.setState({modal: {...this.state.modal, changeTeam: null}})
        } else if (e.target.id === "reportmodal") {
            this.fetchEmployees(moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
            this.setState({newDriver: false, modal: {view: '', id: '', data: {}}, summary: '', summary2: '', filters: {...this.state.filters, from: '', to: ''}})
        } else {
            this.setState({modal: {view: '', date: '', id: '', data: {}}})
        }
    }

    toggleLogs = e => {
        e.preventDefault()
        if (this.state.modal.logDate) this.setState({modal: {...this.state.modal, logDate: null}})
        else this.setState({modal: {...this.state.modal, logDate: e.target.id}})
    }

    toggleEnabled = e => {
        if (this.state.view === "workers") this.setState({filters: {...this.state.filters, working: !this.state.filters.working}})
        else if (this.state.view === "jobs") this.setState({filters: {...this.state.filters, [e.target.id]: e.target.checked}})
    }

    onChange = e => {
        e.preventDefault()
        let id = e.target.id
        if (this.state.view === "jobs") this.setState({filters: {...this.state.filters, [id]: e.target.value}})
        else {
            if (id === "from") {
                if (this.state.filters.to) this.fetchEmployees(e.target.value, this.state.filters.to)
            } else if (id === "to") {
                if (this.state.filters.to) this.fetchEmployees(this.state.filters.from, e.target.value)
            }
            this.setState({filters: {...this.state.filters, [id === 'namesearch' ? 'name' : id === 'typesearch' ? 'type' : id === 'from' ? 'from' : id === 'to' ? 'to' : '']: e.target.value}})
        }
    }

    clear = e => {
        e.preventDefault()
        this.setState({filters: {name: '', type: '', active: false}})
    }

    onChangeNewUserType = e => {
        e.preventDefault()
        if (e.target.value === "forklift") this.setState({newDriver: true})
        else if (this.state.newDriver) this.setState({newDriver: false})
    }

    toggleActive = e => {
        this.setState({modal: {...this.state.modal, data: {...this.state.modal.data, active: e.target.checked}}})
    }

    onChangeJobModal = e => {
        e.preventDefault()
        
        if (this.state.modal.addItem?.type) {
            if (this.state.modal.addItem.type === "product") {
                if (this.state.modal.addItem.countModal) {
                    this.setState({modal: {...this.state.modal, data: {...this.state.modal.data, product: {...this.state.modal.data.product, [this.state.modal.addItem.item]: e.target.className === "add-main-item" ? document.getElementById("add-item-count").value || 0 : "side"}}, addItem: null}})
                } else {
                    this.setState({modal: {...this.state.modal, addItem: {...this.state.modal.addItem, item: e.target.id, countModal: true}}})
                }
            } else {
                if (this.state.modal.addItem.type === "workers") {
                    if (this.state.modal.addItem.temp.includes(e.target.id)) {
                        this.setState(prevState => {
                            return {
                                modal: {
                                    ...prevState.modal,
                                    addItem: {
                                        ...prevState.modal.addItem,
                                        temp: prevState.modal.addItem.temp.filter(x => x !== e.target.id)
                                    }
                                }
                            }
                        })
                    } else this.setState({modal: {...this.state.modal, addItem: {...this.state.modal.addItem, temp: [...this.state.modal.addItem.temp, e.target.id]}}})
                } else {
                    if (this.state.modal.data?.[this.state.modal.addItem?.type] && this.state.modal.data?.[this.state.modal.addItem?.type].includes(e.target.id)) {
                        this.setState({modal: {...this.state.modal, addItem: null}})
                        return
                    }
                    this.setState({modal: {...this.state.modal, addItem: null, data: {...this.state.modal.data, [this.state.modal.addItem?.type]: [...(this.state.modal.data?.[this.state.modal.addItem?.type] || []), e.target.id]}}})
                }
            }
        } else this.setState({modal: {...this.state.modal, data: {...this.state.modal.data, [e.target.id]: e.target.value}}})
    }

    submitWorkers = e => {
        e.preventDefault()

        this.setState({modal: {...this.state.modal, addItem: null, data: {...this.state.modal.data, workers: this.state.modal.addItem.temp}}})
    }

    removeJobItem = e => {
        e.preventDefault()
        
        let parentType = e.target.getAttribute("parenttype")
        let data = JSON.parse(JSON.stringify(this.state.modal.data))
        if (parentType === "product") {
            delete data.product[e.target.id]
        } else {
            data[parentType].splice(data[parentType].indexOf(e.target.id), 1)
        }
        this.setState({modal: {...this.state.modal, data}})
    }

    toggleAlused = e => {
        e.preventDefault()
        let alused = [...this.state.toggledAlused]
        if (alused.includes(e.target.id)) {
            alused = alused.filter(item => item !== e.target.id)
            this.setState({toggledAlused: alused})
        } else this.setState({toggledAlused: [...alused, e.target.id]})
    }

    finishRest = e => {
        e.preventDefault()
        if (this.state.modal.data.type !== "production") return
        this.setState({finishRestModal: e.target.id})
    }

    finishRestConfirm = e => {
        e.preventDefault()
        
        if (e.target.className === "finish") {
            let isTeam = this.state.teams[this.state.finishRestModal] ? true : false
            this.socket.emit("finish_job", {finishJob: {}, jobID: this.state.modal.data._id, workerID: this.state.finishRestModal, isTeam})
        }
        if (e.target.className === "cancel") {
            let isTeam = this.state.teams[this.state.finishRestModal] ? true : false
            this.socket.emit("cancel_job", {cardID: isTeam ? this.state.teams[this.state.finishRestModal].cardID : this.state.workers[this.state.finishRestModal].cardID, job: this.state.modal.data._id, isTeam})
        }
        
        this.setState({finishRestModal: null})
    }

    addNew = e => {
        e.preventDefault()
        if (e.target.id === "addjob") this.setState({modal: {view: 'new', data: {type: "production"}, id: ''}})
        else this.setState({modal: {view: 'new', id: ''}})
    }

    save = e => {
        e.preventDefault()

        if (this.state.view === 'workers') {
            let firstName = document.getElementById('firstName').value
            let lastName = document.getElementById('lastName').value
            let birthDate = document.getElementById('birthDate').value
            let type = document.getElementById('type').value
            let isHourly = document.getElementById('isHourly').value === "piece" ? false : true
            let cardID = document.getElementById('cardID').value
            let language = document.getElementById('language').value

            fetch(`${process.env.REACT_APP_SERVER_URL}/employee/save`, {
                method: 'POST',
                body: JSON.stringify({
                    _id: this.state.modal.id, firstName, lastName, birthDate, type, isHourly, cardID, language
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                if (data) {
                    this.setState({modal: {view: '', id: ''}, workers: {...this.state.workers, [data._id]: data}})
                }
            }).catch(err => {
                console.log(err)
            })
        } else if (this.state.view === 'jobs') {
            if (this.state.jobView === 'jobs') {
                let {alus, client, comments, workers, duedate, pakend, pm, product, silt, per, type, active, tas} = this.state.modal.data
                let data = {}
                if (type === "processor") {
                    data = {type, duedate, product, alus, pm, comments, workers, active, tas}
                } else {
                    data = {type, duedate, product, silt, per, client, pakend, alus, pm, comments, workers, active, tas}
                }

                fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/save`, {
                    method: 'POST',
                    body: JSON.stringify({
                        _id: this.state.modal.id, ...data
                    }),
                    credentials: 'include',
                    headers: {"Content-Type": "application/json"}
                }).then(res => {
                    return res.json()
                }).then(data => {
                    if (data._id) {
                        this.setState({modal: {view: '', id: ''}, jobs: {...this.state.jobs, [data._id]: data}})
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else if (this.state.jobView === 'teams') {
                let name = document.getElementById('name').value
                let cardID = document.getElementById('cardID').value

                fetch(`${process.env.REACT_APP_SERVER_URL}/team/save`, {
                    method: 'POST',
                    body: JSON.stringify({_id: this.state.modal.id, name, cardID}),
                    credentials: 'include',
                    headers: {"Content-Type": "application/json"}
                }).then(res => {
                    return res.json()
                }).then(data => {
                    if (data) {
                        this.setState({modal: {view: '', id: ''}, teams: {...this.state.teams, [data._id]: data}})
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        } else if (this.state.view === 'users') {
            let name = document.getElementById('name').value
            let type = document.getElementById('type').value
            let password = document.getElementById('password').value
            let driver = document.getElementById('driver')?.value || ""
            let driverName = `${this.state.workers[driver]?.firstName} ${this.state.workers[driver]?.lastName}`

            fetch(`${process.env.REACT_APP_SERVER_URL}/users/save`, {
                method: 'POST',
                body: JSON.stringify({
                    _id: this.state.modal.id, name, type, password, driver: {name: driverName, id: driver}
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                return res.json()
            }).then(data => {
                if (data._id) {
                    this.setState({newDriver: false, modal: {view: '', id: ''}, users: {...this.state.users, [data._id]: data}})
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    invalidateRevalidate = e => {
        e.preventDefault()

        fetch(`${process.env.REACT_APP_SERVER_URL}/employee/valid`, {
            method: 'POST',
            body: JSON.stringify({
                _id: this.state.modal.id, valid: !this.state.workers[this.state.modal.id].valid
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            if (data.id) {
                this.setState({workers: {...this.state.workers, [data.id]: {...this.state.workers[data.id], valid: !this.state.workers[this.state.modal.id].valid}}})
            }
        }).catch(err => {
            console.log(err)
        })
    }

    remove = e => {
        e.preventDefault()

        if (this.state.view === 'workers') {
            fetch(`${process.env.REACT_APP_SERVER_URL}/employee/remove`, {
                method: 'POST',
                body: JSON.stringify({
                    _id: this.state.modal.id
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                if (res.status === 200) {
                    let workers = this.state.workers
                    delete workers[this.state.modal.id]
                    this.setState({modal: {view: '', id: ''}, workers})
                }
            }).catch(err => {
                console.log(err)
            })
        } else if (this.state.view === 'users') {
            fetch(`${process.env.REACT_APP_SERVER_URL}/users/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    _id: this.state.modal.id
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                if (res.status === 200) {
                    let users = this.state.users
                    delete users[this.state.modal.id]
                    this.setState({modal: {view: '', id: ''}, users})
                }
            }).catch(err => {
                console.log(err)
            })
        } else if (this.state.view === 'jobs') {
            fetch(`${process.env.REACT_APP_SERVER_URL}/team/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    _id: this.state.modal.id
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                if (res.status === 200) {
                    let teams = this.state.teams
                    delete teams[this.state.modal.id]
                    this.setState({modal: {view: '', id: ''}, teams})
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    deleteJob = e => {
        e.preventDefault()

        fetch(`${process.env.REACT_APP_SERVER_URL}/jobs/delete`, {
            method: 'POST',
            body: JSON.stringify({
                _id: this.state.modal.id
            }),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            return res.json()
        }).then(data => {
            if (!data.length) this.setState({modal: {view: '', id: ''}})
            let jobs = {}
            data.forEach((job, i) => {
                jobs = {...jobs, [job._id]: job}
                if (i+1 === data.length) {
                    this.setState({jobs, modal: {view: '', id: ''}})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    saveTime = e => {
        e.preventDefault()
        
        let startDate = document.getElementById("startdate").value
        let startTime = document.getElementById("starttime").value
        let endDate = document.getElementById("enddate").value
        let endTime = document.getElementById("endtime").value
        // console.log(!!startDate, !!startTime, !!endDate, !!endTime, this.state.modal)

        if (!startDate && !endDate) return
        if (!startTime && !endTime) return
        if (!startDate && !endTime) return
        if (!startTime && !endDate) return
        if (!startTime || !startDate) return
        
        if (!this.state.modal.data.from && !this.state.modal.data.to) {
            this.socket.emit("savetime", {startDate, startTime, endDate, endTime, modal: this.state.modal, type: 'add'})
        } else {
            this.socket.emit("savetime", {startDate, startTime, endDate, endTime, modal: this.state.modal, type: 'save'})
        }
        this.setState({modal: {...this.state.modal, date: '', data: {}}})
    }

    deleteTime = e => {
        e.preventDefault()

        this.socket.emit("savetime", {modal: this.state.modal, type: 'delete'})
        this.setState({modal: {...this.state.modal, date: '', data: {}}})
    }

    toggleExportModal = e => {
        e.preventDefault()

        if (this.state.modal.view === "export") this.setState({modal: {view: '', date: '', id: '', data: {}}})
        else this.setState({modal: {view: "export", year: "", month: ""}})
    }

    calculateExport = e => {
        e.preventDefault()
        
        // let data = {}

        // if (this.state.modal.view === "workerdetails") {
        //     let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
        //     data = {items: this.state.modal.items, dates: {}}
        //     Array(moment(month).daysInMonth()).fill(0).forEach((key, i) => {
        //         let day = moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")
        //         data = {...data, dates: {...data.dates, [day]: this.state.workers[this.state.modal.id].work?.[day] || {}}}
        //     })
        // } else if (this.state.modal.view === "export") {
        //     Object.keys(this.state.workers).map(key => {
        //         let worker = this.state.workers[key]
        //         let name = `${worker.firstName} ${worker.lastName}`
        //         data = {...data, [name]: {}}
        //         let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
        //         Array(moment(month).daysInMonth()).fill(0).forEach((key, i) => {
        //             let day = moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")
        //             Object.keys(worker?.work?.[day] || {}).forEach(key => {
        //                 data = {...data, [name]: {...data[name], [key]: (data[name][key] || 0)+worker.work[day][key]}}
        //             })
        //         })
        //     })
        // } else return
        // alert("Andmed kopeeritud")
        // navigator.clipboard.writeText(JSON.stringify(data))
    }

    showHelp = e => {
        e.preventDefault()
        if (e.target.tagName !== "H1") return
        this.setState({logsHelp: e.target.getAttribute("help")})
    }

    hideHelp = e => {
        e.preventDefault()
        this.setState({logsHelp: ""})
    }

    toggleWorkerVisibility = e => {
        e.preventDefault()
        this.setState({workerVisibility: !this.state.workerVisibility})
    }

    filterTeamHistory = e => {
        e.preventDefault()
        this.socket.emit("teamHistory", {start: this.state.filters.from || "", end: this.state.filters.to || "", team: this.state.modal.id})
        this.setState({modal: {...this.state.modal, from: this.state.filters.from, to: this.state.filters.to, filtered: true}})
    }

    filterTeamMembers = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, changeTeam: {...this.state.modal.changeTeam, filter: e.target.value}}})
    }

    addTeamMember = e => {
        e.preventDefault()
        this.setState({modal: {...this.state.modal, changeTeam: {...this.state.modal.changeTeam, team: [...this.state.modal.changeTeam.team, e.target.id]}}})
    }

    removeTeamMember = e => {
        e.preventDefault()
        let team = this.state.modal.changeTeam.team
        team = team.filter(x => x !== e.target.id)
        this.setState({modal: {...this.state.modal, changeTeam: {...this.state.modal.changeTeam, team}}})
    }

    saveTeam = e => {
        e.preventDefault()
        this.socket.emit("saveTeamChanges", {id: this.state.modal.id, time: this.state.modal.changeTeam.id, team: this.state.modal.changeTeam.team, from: this.state.modal.from, to: this.state.modal.to, filtered: this.state.modal.filtered || false})
    }

    render() {
        let settings = this.state.settings
        return (
            <main>
                <div className="manage">
                    {/* <div className="manage-nav">
                        <div className="manage-nav__workers" id="workers" onClick={this.route}>
                            <h1 className={this.state.view === "workers" ? "active" : ""}>Töölised</h1>
                        </div>
                        <div className="manage-nav__jobs" id="jobs" onClick={this.route}>
                            <h1 className={this.state.view === "jobs" ? "active" : ""}>Tööd</h1>
                        </div>
                        <div className="manage-nav__users" id="users" onClick={this.route}>
                            <h1 className={this.state.view === "users" ? "active" : ""}>Kasutajad</h1>
                        </div>
                    </div> */}
                    <div className={`manage-content ${this.state.view === 'users' ? "manage-content--users" : ""}`}>
                        {this.state.view === 'workers' &&
                        <div className="manage-workers">
                            <div className="header">
                                <h1>Nimi:</h1>
                                <input className="namesearch" type="text" id="namesearch" onChange={this.onChange} />
                                <h1>Töötaja tüüp:</h1>
                                <select id="typesearch" onChange={this.onChange}>
                                    <option value=""></option>
                                    <option value="production">Tootja</option>
                                    <option value="processor">Töötleja</option>
                                    <option value="forklift">Tõstukijuht</option>
                                    <option value="nightguard">Öövaht</option>
                                </select>
                                <h1>Ainult kohal:</h1>
                                <input className="switchbutton" type="checkbox" onChange={this.toggleEnabled}/>
                                <a className="addnew" onClick={this.addNew}>Lisa uus <i className="fas fa-plus-circle"/></a>
                                {/* <a className="refresh" onClick={this.refresh}><i className="fas fa-sync-alt"/></a> */}
                            </div>
                            <div className="manage-content1">
                                {Object.keys(this.state.workers).map(key => {
                                    let {firstName, lastName, type, checkedIn} = this.state.workers[key]
                                    let fullName = firstName + " " + lastName
                                    if (this.state.filters.working === true && checkedIn === false) return
                                    if (this.state.filters.name && !(fullName||"").toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                    if (this.state.filters.type && this.state.filters.type !== type) return
                                    return <div className="worker" key={key}>
                                        <h1>{firstName} {lastName}</h1>
                                        <h1>{type === 'production' ? "Tootja" : type === 'processor' ? "Töötleja" : type === 'forklift' ? "Tõstukijuht" : type === 'nightguard' ? "Öövaht" : ""}</h1>
                                        <h1>{checkedIn ? "Kohal" : "Pole kohal"}</h1>
                                        <h1 className="details" id={key} onClick={this.toggleModal}>Vaata lähemalt</h1>
                                        <h1 className="report" id={key} onClick={this.toggleModal}>Raport</h1>
                                    </div>
                                })}
                            </div>
                        </div> ||
                        this.state.view === 'jobs' &&
                        <div className="manage-workers">
                            <div className={`header${this.state.filters.active ? " header--filters-active" : ""}`}>
                                {this.state.jobView === "jobs" && 
                                    <a className={`linkbutton${this.state.filters.active ? " linkbutton--active" : ""}`} onClick={this.toggleFilters}><i className="fas fa-angle-right"/> Filtreeri</a> ||
                                    <>
                                        <h1 style={{'zIndex': 2}}>Nimi:</h1>
                                        <input className="namesearch" type="text" id="namesearch" onChange={this.onChange} />
                                    </>
                                }
                                <div className="header-nav">
                                    <a className={`header-nav--button${this.state.jobView === "jobs" ? " header-nav--button--active": ""}`} id="jobs" onClick={this.jobRoute}>Tellimused</a>
                                    <a className={`header-nav--button${this.state.jobView === "workers" ? " header-nav--button--active": ""}`} id="workers" onClick={this.jobRoute}>Töölised</a>
                                    <a className={`header-nav--button${this.state.jobView === "teams" ? " header-nav--button--active": ""}`} id="teams" onClick={this.jobRoute}>Meeskonnad</a>
                                </div>
                                {this.state.filters.active && <a className="linkbutton linkbutton--clear" onClick={this.clear}>Tühjenda filtrid</a>}
                                {(this.state.jobView === "jobs" && this.state.filters.active) &&
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Töötaja tüüp</h1>
                                        {/* <input type="text" id="koorem" value={this.state.filters.koorem || ""} onChange={this.onChange} /> */}
                                        <select id="type" value={this.state.filters.type || ""} onChange={this.onChange}>
                                            <option value=""></option>
                                            <option value="production">Tootja</option>
                                            <option value="processor">Töötleja</option>
                                        </select>
                                    </div>
                                    <div className="filter">
                                        <h1>Tellija</h1>
                                        <input type="text" id="client" value={this.state.filters.client || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tööline</h1>
                                        <input type="text" id="worker" value={this.state.filters.worker || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Toodang</h1>
                                        <input type="text" id="product" value={this.state.filters.product || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Pakend</h1>
                                        <input type="text" id="pakend" value={this.state.filters.pakend || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Silt</h1>
                                        <input type="text" id="silt" value={this.state.filters.silt || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Pakkematerjal</h1>
                                        <input type="text" id="pm" value={this.state.filters.pm || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Sisestamise kuupäev</h1>
                                        <input type="date" id="addedfrom" value={this.state.filters.addedfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="addedto" value={this.state.filters.addedto || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="datefilter">
                                        <h1>Laadimise kuupäev</h1>
                                        <input type="date" id="duedatefrom" value={this.state.filters.duedatefrom || ""} onChange={this.onChange} />
                                        <input type="date" id="duedateto" value={this.state.filters.duedateto || ""} onChange={this.onChange} />
                                    </div>
                                </div>}
                                {this.state.jobView === "jobs" ? <a className="addnew" id="addjob" onClick={this.addNew}>Lisa uus <i className="fas fa-plus-circle"/></a> : this.state.jobView === "workers" ? <a className="addnew" onClick={this.toggleExportModal} style={{display: "none"}}>Ekspordi</a> : <a className="addnew" onClick={this.addNew}>Lisa uus <i className="fas fa-plus-circle"/></a>}
                            </div>
                            <div className="manage-content1">
                                {this.state.jobView === "jobs" && <div className="table centered" key="2">
                                    <div className="row row-sticky">
                                        <h1 className="wares-number">Tellimus</h1>
                                        <h1 className="wares-8">Laadimise aeg</h1>
                                        {/* <h1 className="wares-7">Töötaja tüüp</h1> */}
                                        {/* <h1 className="wares-7">Tellija</h1> */}
                                        <h1 className={`${this.state.workerVisibility ? "wares-10" : "wares-5"} wares-clickable`} onClick={this.toggleWorkerVisibility}>Töölised</h1>
                                        <h1 className="wares-8">Progress</h1>
                                        <h1 className="wares-5">Aktiivne</h1>
                                        <h1 className={`${this.state.workerVisibility ? "wares-10" : "wares-5"} wares-clickable`} onClick={this.toggleWorkerVisibility}>Kohal</h1>
                                        <h1 className="wares-10 wares-left">Toodang</h1>
                                        <h1 className="wares-10 wares-left">Silt</h1>
                                        <h1 className="wares-10 wares-left">Pakend</h1>
                                        <h1 className="wares-10 wares-left">Alus</h1>
                                        <h1 className="wares-10 wares-left">Pakkematerjal</h1>
                                        {/* <h1 className="wares-6">Valmis</h1> */}
                                        <h1 className="wares-15">Kommentaarid</h1>
                                        <h1 className="wares-7">Loomise aeg</h1>
                                    </div>
                                    {Object.keys(this.state.jobs || {}).sort((a, b) => {
                                        if (this.state.jobs[a].finished === this.state.jobs[b].finished) {
                                            if (this.state.jobs[a].duedate > this.state.jobs[b].duedate) return 1
                                            else return -1
                                        } else {
                                            if (this.state.jobs[a].finished) return 1
                                            else return -1
                                        }
                                    }).map(key => {
                                        let {type, duedate, product, silt, workers, client, number, pakend, alus, pm, comments, createdAt, active, finished, activeWorkers, progress} = this.state.jobs[key]
                                        let filters = this.state.filters
                                        let shouldReturn = []
                                        if (filters.type && filters.type !== type) return
                                        if (filters.client && !(client || " ").toLowerCase().includes(filters.client.toLowerCase())) return
                                        if (filters.addedfrom > moment(createdAt).format("YYYY-MM-DD") || filters.addedto < moment(createdAt).format("YYYY-MM-DD")) return
                                        if (filters.duedatefrom > moment(duedate).format("YYYY-MM-DD") || filters.duedateto < moment(duedate).format("YYYY-MM-DD")) return
                                        if (filters.worker) {
                                            let boolArray = []
                                            if (!workers.length) shouldReturn.push(true)
                                            workers.forEach((key, i) => {
                                                if ((this.state.workers[key].firstName + " " + this.state.workers[key].lastName).toLowerCase().includes(filters.worker.toLowerCase())) boolArray.push(i)
                                                if (i+1 === workers.length) {
                                                    if (!boolArray.length) shouldReturn.push(true)
                                                }
                                            })
                                        }
                                        if (filters.product) {
                                            let boolArray = []
                                            if (!Object.keys(product).length) shouldReturn.push(true)
                                            Object.keys(product).forEach((key, i) => {
                                                if (this.state.stock[key]?.name.toLowerCase().includes(filters.product.toLowerCase())) boolArray.push(i)
                                                if (i+1 === Object.keys(product).length) {
                                                    if (!boolArray.length) shouldReturn.push(true)
                                                }
                                            })
                                        }
                                        if (filters.pakend) {
                                            let boolArray = []
                                            if (!pakend.length) shouldReturn.push(true)
                                            pakend.forEach((key, i) => {
                                                if (this.state.stock[key]?.name.toLowerCase().includes(filters.pakend.toLowerCase())) boolArray.push(i)
                                                if (i+1 === pakend.length) {
                                                    if (!boolArray.length) shouldReturn.push(true)
                                                }
                                            })
                                        }
                                        if (filters.silt) {
                                            let boolArray = []
                                            if (!silt.length) shouldReturn.push(true)
                                            silt.forEach((key, i) => {
                                                if (this.state.stock[key]?.name.toLowerCase().includes(filters.silt.toLowerCase())) boolArray.push(i)
                                                if (i+1 === silt.length) {
                                                    if (!boolArray.length) shouldReturn.push(true)
                                                }
                                            })
                                        }
                                        if (filters.pm) {
                                            let boolArray = []
                                            if (!pm.length) shouldReturn.push(true)
                                            pm.forEach((key, i) => {
                                                if (this.state.stock[key]?.name.toLowerCase().includes(filters.pm.toLowerCase())) boolArray.push(i)
                                                if (i+1 === pm.length) {
                                                    if (!boolArray.length) shouldReturn.push(true)
                                                }
                                            })
                                        }

                                        let availableWorkers = []
                                        workers?.forEach(key => {
                                            if (this.state.workers?.[key]?.checkedIn) availableWorkers.push(key)
                                        })

                                        if (shouldReturn.includes(true)) return
                                        return <div className={`row${finished ? " row-finished" : ""}`} id={key} key={key} onClick={this.toggleModal}>
                                            <h1 className="wares-number">{client+number}</h1>
                                            <h1 className="wares-8">{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</h1>
                                            {/* <h1 className="wares-7">{type === "processor" ? "Töötleja" : "Tootja"}</h1> */}
                                            {/* <h1 className="wares-7">{client}</h1> */}
                                            <h1 className={`${this.state.workerVisibility ? "wares-10" : "wares-5"}`}>{this.state.workerVisibility ? (workers || []).map(worker => this.state.workers[worker] ? `${this.state.workers[worker]?.firstName || ""} ${this.state.workers[worker]?.lastName || ""}\n` : `${this.state.teams[worker]?.name || ""}\n`) : (workers.length || 0)}</h1>
                                            <h1 className="wares-8">{type === "processor" ? "" : type === "production" ? `${progress.ovr?.[Object.keys(product)[0]] || 0}(+${activeWorkers.length}) / ${product[Object.keys(product)[0]]}` : ""}</h1>
                                            <h1 className="wares-5">{active ? "Jah" : "Ei"}</h1>
                                            <h1 className={`${this.state.workerVisibility ? "wares-10" : "wares-5"}`}>{this.state.workerVisibility ? (availableWorkers || []).map(worker => this.state.workers[worker] ? `${this.state.workers[worker]?.firstName || ""} ${this.state.workers[worker]?.lastName || ""}\n` : `${this.state.teams[worker]?.name || ""}\n`) : (availableWorkers.length || 0)}</h1>
                                            <h1 className="wares-10 wares-left">{Object.keys(product || {}).map(product => `${this.state.stock[product]?.name || ""}\n`)}</h1>
                                            <h1 className="wares-10 wares-left">{(silt || []).map(silt => `${this.state.stock[silt]?.name || ""}\n`)}</h1>
                                            <h1 className="wares-10 wares-left">{(pakend || []).map(pakend => `${this.state.stock[pakend]?.name || ""}\n`)}</h1>
                                            <h1 className="wares-10 wares-left">{(alus || []).map(alus => `${this.state.stock[alus]?.name || ""}\n`)}</h1>
                                            <h1 className="wares-10 wares-left">{(pm || []).map(pm => `${this.state.stock[pm]?.name || ""}\n`)}</h1>
                                            {/* <h1 className="wares-6">{finished ? "Jah" : "Ei"}</h1> */}
                                            <h1 className="wares-15">{comments}</h1>
                                            <h1 className="wares-7">{moment(createdAt).format("DD/MM/YYYY")}</h1>
                                        </div>
                                    })}
                                </div> || 
                                this.state.jobView === "workers" && Object.keys(this.state.workers).map(key => {
                                    let {firstName, lastName} = this.state.workers[key]
                                    let name = firstName + " " + lastName
                                    if (this.state.filters.namesearch && !name.toLowerCase().includes(this.state.filters.namesearch.toLowerCase())) return
                                    return <div className="worker" key={key}>
                                        <h1>{firstName} {lastName}</h1>
                                        <h1 className="details" id={key} onClick={this.toggleModalWorkerdetails}>Vaata lähemalt</h1>
                                    </div>
                                }) ||
                                this.state.jobView === "teams" && Object.keys(this.state.teams).map(key => {
                                    let {name, cardID} = this.state.teams[key]
                                    if (this.state.filters.namesearch && !name.toLowerCase().includes(this.state.filters.namesearch.toLowerCase())) return
                                    return <div className="worker" key={key}>
                                        <h1>{name}</h1>
                                        <h1>{cardID}</h1>
                                        <h1 className="details teamdetails" id={key} onClick={this.toggleModal}>Vaata lähemalt</h1>
                                        <h1 className="report" id={key} onClick={this.toggleModal}>Ajalugu</h1>
                                    </div>
                                })}
                            </div>
                        </div> ||
                        this.state.view === 'users' &&
                        <div className="manage-workers">
                            <div className="header">
                                <h1>Nimi:</h1>
                                <input className="namesearch" type="text" id="namesearch" onChange={this.onChange} />
                                <h1>Kasutaja tüüp:</h1>
                                <select id="typesearch" onChange={this.onChange}>
                                    <option value=""></option>
                                    <option value="production">Tootja seade</option>
                                    <option value="forklift">Tõstukijuhi seade</option>
                                    <option value="main">Emaarvuti</option>
                                    <option value="management">Juhtkond</option>
                                </select>
                                <a className="addnew" onClick={this.addNew}>Lisa uus <i className="fas fa-plus-circle"/></a>
                                {/* <a className="refresh" onClick={this.refresh}><i className="fas fa-sync-alt"/></a> */}
                            </div>
                            <div className="manage-content1">
                                {Object.keys(this.state.users).map(key => {
                                // console.log(this.state.users[key])
                                let {name, type} = this.state.users[key]
                                if (this.state.filters.name && !name.toLowerCase().includes(this.state.filters.name.toLowerCase())) return
                                if (this.state.filters.type && this.state.filters.type !== type) return
                                return <div className="worker" key={key}>
                                    <h1>{name}</h1>
                                    <h1>{type === 'management' ? "Juhtkond" : type === 'production' ? "Tootja" : type === 'forklift' ? "Tõstukijuht" : type === 'main' ? "Emaarvuti" : ""}</h1>
                                    <h1 className="details" id={key} onClick={this.toggleModal}>Vaata lähemalt</h1>
                                </div>
                                })}
                            </div>
                        </div>
                        }
                    </div>
                    {(this.state.modal.view === "worker" || this.state.modal.view === "new" || this.state.modal.view === "job" || this.state.modal.view === "team") &&
                    <div className="modal">
                        {(this.state.modal.view === "worker" || this.state.modal.view === "new" || this.state.modal.view === "team") && <div className={`modal-content ${(this.state.view === 'users' || this.state.view === 'jobs') ? "modal-content--user" : ""}`}>
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            {this.state.view === "workers" &&
                            <div className="edit-worker">
                                <h1>Eesnimi</h1>
                                <input type="text" id="firstName" defaultValue={this.state.workers?.[this.state.modal.id]?.firstName} />
                                <h1>Perenimi</h1>
                                <input type="text" id="lastName" defaultValue={this.state.workers?.[this.state.modal.id]?.lastName} />
                                <h1>Sünniaeg</h1>
                                <input type="date" id="birthDate" defaultValue={this.state.workers?.[this.state.modal.id]?.birthDate} />
                                <h1>Töötaja tüüp</h1>
                                <select id="type" defaultValue={this.state.workers?.[this.state.modal.id]?.type}>
                                    <option value=""></option>
                                    <option value="production">Tootja</option>
                                    <option value="processor">Töötleja</option>
                                    <option value="forklift">Tõstukijuht</option>
                                    <option value="nightguard">Öövaht</option>
                                </select>
                                <h1>Tööliik</h1>
                                <select id="isHourly" defaultValue={this.state.workers?.[this.state.modal.id]?.isHourly === undefined ? "" : (this.state.workers?.[this.state.modal.id]?.isHourly === true ? "hourly" : "piece")}>
                                    <option value=""></option>
                                    <option value="hourly">Tunnitöö</option>
                                    <option value="piece">Tükitöö</option>
                                </select>
                                <h1>Kiibi ID</h1>
                                <input type="text" id="cardID" defaultValue={this.state.workers?.[this.state.modal.id]?.cardID} />
                                <h1>Keel</h1>
                                <select id="language" defaultValue={this.state.workers?.[this.state.modal.id]?.language}>
                                    <option value=""></option>
                                    <option value="est">EST</option>
                                    <option value="rus">RUS</option>
                                </select>
                            </div> ||
                            this.state.view === "users" &&
                            <div className="edit-worker">
                                <h1>Kasutajanimi</h1>
                                <input type="text" id="name" defaultValue={this.state.users?.[this.state.modal.id]?.name} />
                                <h1>Kasutaja tüüp</h1>
                                <select id="type" onChange={this.onChangeNewUserType} defaultValue={this.state.users?.[this.state.modal.id]?.type}>
                                    <option value=""></option>
                                    <option value="production">Tootja seade</option>
                                    <option value="forklift">Tõstukijuhi seade</option>
                                    <option value="main">Emaarvuti</option>
                                    <option value="management">Juhtkond</option>
                                </select>
                                {(this.state.users?.[this.state.modal.id]?.data?.driver || this.state.newDriver) && <h1>Tõstukijuht</h1>}
                                {(this.state.users?.[this.state.modal.id]?.data?.driver || this.state.newDriver) && <select id="driver" defaultValue={this.state.users?.[this.state.modal.id]?.data?.driver?.id}>
                                    <option value=""></option>
                                    {Object.keys(this.state.workers).map(key => {
                                        let worker = this.state.workers[key]
                                        if (worker.type !== "forklift") return
                                        return <option value={worker._id} key={key}>{worker.firstName} {worker.lastName}</option>
                                    })}
                                </select>}
                                <h1>{this.state.users?.[this.state.modal.id] ? "Muuda salasõna" : "Salasõna"}</h1>
                                <input type="text" id="password" />
                            </div> ||
                            (this.state.view === "jobs" && this.state.jobView === "teams" && (this.state.modal.view === "team" || this.state.modal.view === "new")) &&
                            <div className="edit-worker">
                                <h1>Meeskonna nimi</h1>
                                <input type="text" id="name" defaultValue={this.state.teams?.[this.state.modal.id]?.name} />
                                <h1>Kiibi ID</h1>
                                <input type="text" id="cardID" defaultValue={this.state.teams?.[this.state.modal.id]?.cardID} />
                            </div> ||
                            (this.state.view === "jobs" && this.state.modal.view === "new") &&
                            <div className="edit-worker">
                                <h1>Töötaja tüüp</h1>
                                <select id="type" value={this.state.jobs?.[this.state.modal.id]?.type} onChange={this.onChangeJobModal}>
                                    <option value="production">Tootja</option>
                                    <option value="processor">Töötleja</option>
                                </select>
                                <h1>Laadimise kuupäev</h1>
                                <input type="date" id="duedate" value={this.state.modal.data?.duedate || this.state.jobs?.[this.state.modal.id]?.duedate || ""} onChange={this.onChangeJobModal} />
                                <h1>Toodang</h1>
                                {Object.keys(this.state.modal.data?.product || {}).map(key => {
                                    let count = this.state.modal.data.product[key]
                                    return <span className="jobitem" key={key}>
                                        <p>{count === "side" ? "Kõrvaltoode" : `${count}x`} {this.state.stock[key]?.name}</p>
                                        <span parenttype="product" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !Object.keys(this.state.modal.data.product || {}).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="product" onClick={this.toggleModal}>Lisa toodang</a>}
                                {this.state.modal.data.type === "production" && <h1>Tooteid alusel</h1>}
                                {this.state.modal.data.type === "production" && <input type="text" id="per" value={this.state.modal.data?.per || this.state.jobs?.[this.state.modal.id]?.per} onChange={this.onChangeJobModal} />}
                                {this.state.modal.data.type === "production" && <h1>Tellija</h1>}
                                {this.state.modal.data.type === "production" && <input type="text" id="client" value={this.state.modal.data?.client || this.state.jobs?.[this.state.modal.id]?.client} onChange={this.onChangeJobModal} />}
                                {this.state.modal.data.type === "production" && <h1>Silt</h1>}
                                {this.state.modal.data.type === "production" && (this.state.modal.data?.silt || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="silt" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {(this.state.modal.data.type === "production" && !(this.state.modal.data.silt || []).length) && <a className="add-item" id="silt" onClick={this.toggleModal}>Lisa silt</a>}
                                {this.state.modal.data.type === "production" && <h1>Pakend</h1>}
                                {this.state.modal.data.type === "production" && (this.state.modal.data?.pakend || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="pakend" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {(this.state.modal.data.type === "production" && !(this.state.modal.data.pakend || []).length) && <a className="add-item" id="pakend" onClick={this.toggleModal}>Lisa pakend</a>}
                                <h1>Alus</h1>
                                {(this.state.modal.data?.alus || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="alus" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !(this.state.modal.data.alus || []).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="alus" onClick={this.toggleModal}>Lisa alus</a>}
                                <h1>Pakkematerjal</h1>
                                {(this.state.modal.data?.pm || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="pm" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !(this.state.modal.data.pm || []).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="pm" onClick={this.toggleModal}>Lisa pakkematerjal</a>}
                                <h1>Töölised</h1>
                                {(this.state.modal.data?.workers || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        {this.state.workers[key] ? <p>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</p> :
                                        <p>{this.state.teams[key]?.name}</p>}
                                        <span parenttype="workers" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                <a className="add-item" id="workers" onClick={this.toggleModal}>Lisa töölised</a>
                                <h1>Tasustamine</h1>
                                <select id="tas" value={this.state.modal.data?.tas || ""} onChange={this.onChangeJobModal}>
                                    <option value=""></option>
                                    <option value="1">Tükitöö</option>
                                    <option value="0">Tunnitöö</option>
                                </select>
                                <h1>Kommentaarid</h1>
                                <textarea id="comments" value={this.state.jobs?.[this.state.modal.id]?.comments} onChange={this.onChangeJobModal} />
                                {this.state.modal.addItem &&
                                <div className="add-item-modal">
                                    <div className={`add-item-modal-container${this.state.modal.addItem.type === "workers" ? " worker-selection" : ""}`}>
                                        <span className="modal-close" onClick={this.closeModal}>&times;</span>
                                        <h1>{this.state.modal.addItem.type === "product" ? "Toodang" : this.state.modal.addItem.type === "pakend" ? "Pakend" : this.state.modal.addItem.type === "alus" ? "Alus" : this.state.modal.addItem.type === "pm" ? "Pakkematerjal" : this.state.modal.addItem.type === "silt" ? "Silt" : ""}</h1>
                                        <input value={this.state.modal.addItem.filter || ""} onChange={this.onChangeAddItemFilter} />
                                        {this.state.modal.addItem?.type === "workers" && <div className="type-selection">
                                            <span className={`left${this.state.modal.selected === "left" ? " selected" : ""}`} id="left" onClick={this.changeAddItemType}>Töötajad</span>
                                            <span className={`right${this.state.modal.selected === "right" ? " selected" : ""}`} id="right" onClick={this.changeAddItemType}>Meeskonnad</span>
                                        </div>}
                                        <div className="item-selection">
                                            {this.state.modal.addItem?.type === "workers" &&
                                            (this.state.modal.selected === "left" ? Object.keys(this.state.workers).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!(this.state.workers[key].firstName + " " + this.state.workers[key].lastName).toLowerCase().includes(filter.toLowerCase())) return
                                                return <span className={`item-selection-worker${this.state.modal.addItem.temp?.includes(key) ? " active" : ""}`} id={key} key={key} onClick={this.onChangeJobModal}>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</span>
                                            }) : Object.keys(this.state.teams).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!(this.state.teams[key]?.name).toLowerCase().includes(filter.toLowerCase())) return
                                                return <span className={`item-selection-worker${this.state.modal.addItem.temp?.includes(key) ? " active" : ""}`} id={key} key={key} onClick={this.onChangeJobModal}>{this.state.teams[key]?.name}</span>
                                            })) ||
                                            Object.keys(this.state.stock).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!this.state.stock[key]?.name.toLowerCase().includes(filter.toLowerCase())) return
                                                if (this.state.modal.addItem.type === "silt" && this.state.stock[key].type !== "Sildid") return
                                                if ((this.state.modal.addItem.type === "pakend" || this.state.modal.addItem.type === "alus" || this.state.modal.addItem.type === "pm") && this.state.stock[key].type !== "Pakkevahendid") return
                                                if (this.state.modal.addItem.type === "product" && (this.state.stock[key].type === "Sildid" || this.state.stock[key].type === "Pakkevahendid")) return
                                                return <span id={key} key={key} onClick={this.onChangeJobModal}>{`${this.state.stock[key]?.name}${this.state.stock[key].type === "Sildid" ? " (silt)" : ""}`}</span>
                                            })}
                                        </div>
                                        {this.state.modal.addItem.type === "workers" && <a className="submit" onClick={this.submitWorkers}>Salvesta</a>}
                                    </div>
                                    {this.state.modal.addItem.countModal && <div className="count-modal">
                                        <div className="count-modal-container">
                                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                                            <h1>{this.state.stock[this.state.modal.addItem.item]?.name}</h1>
                                            <span className="count-label">Kogus:</span>
                                            <input type="number" id="add-item-count" />
                                            <a className="add-side-item" onClick={this.onChangeJobModal}>Kõrvaltoode</a>
                                            <a className="add-main-item" onClick={this.onChangeJobModal}>Lisa</a>
                                        </div>
                                    </div>}
                                </div>}
                            </div>}
                            {this.state.users?.[this.state.modal.id] && <h1 className="delete delete--user" onClick={this.remove}>Kustuta kasutaja</h1>}
                            {(this.state.view === "workers" && this.state.workers?.[this.state.modal.id]) && <h1 className="delete" onClick={this.remove}>Kustuta töötaja</h1>}
                            {(this.state.view === "jobs" && this.state.teams?.[this.state.modal.id]) && <h1 className="delete delete--user" onClick={this.remove}>Kustuta meeskond</h1>}
                            {(this.state.view === "workers" && this.state.workers?.[this.state.modal.id]) && <h1 className="invalidate" onClick={this.invalidateRevalidate}>{this.state.workers?.[this.state.modal.id]?.valid ? "Invalideeri kiip" : "Valideeri kiip"}</h1>}
                            <a className="submit" onClick={this.save}>Salvesta</a>
                        </div> ||
                        this.state.modal.view === "job" &&
                        <div className="job-modal-content">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <div className="edit-job">
                                <h1>Töötaja tüüp</h1>
                                <select id="type" value={this.state.jobs?.[this.state.modal.id]?.type} onChange={this.onChangeJobModal} disabled>
                                    <option value="production">Tootja</option>
                                    <option value="processor">Töötleja</option>
                                </select>
                                <h1>Laadimise kuupäev</h1>
                                <input type="date" id="duedate" value={this.state.modal.data?.duedate || this.state.jobs?.[this.state.modal.id]?.duedate || ""} onChange={this.onChangeJobModal} />
                                <h1>Toodang</h1>
                                {Object.keys(this.state.modal.data?.product || {}).map(key => {
                                    let count = this.state.modal.data.product[key]
                                    return <span className="jobitem" key={key}>
                                        <p>{count === "side" ? "Kõrvaltoode" : `${count}x`} {this.state.stock[key]?.name}</p>
                                        {/* <span parenttype="product" id={key} onClick={this.removeJobItem}>&times;</span> */}
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !Object.keys(this.state.modal.data?.product || {}).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="product" onClick={this.toggleModal}>Lisa toodang</a>}
                                {this.state.modal.data.type === "production" && <h1>Tellija</h1>}
                                {this.state.modal.data.type === "production" && <input type="text" id="client" value={this.state.modal.data?.client || this.state.jobs?.[this.state.modal.id]?.client} disabled />}
                                {this.state.modal.data.type === "production" && <h1>Tooteid alusel</h1>}
                                {this.state.modal.data.type === "production" && <input type="text" id="per" value={this.state.modal.data?.per || this.state.jobs?.[this.state.modal.id]?.per} disabled />}
                                {this.state.modal.data.type === "production" && <h1>Silt</h1>}
                                {this.state.modal.data.type === "production" && (this.state.modal.data?.silt || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="silt" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {(this.state.modal.data.type === "production" && !(this.state.modal.data?.silt || []).length) && <a className="add-item" id="silt" onClick={this.toggleModal}>Lisa silt</a>}
                                {this.state.modal.data.type === "production" && <h1>Pakend</h1>}
                                {this.state.modal.data.type === "production" && (this.state.modal.data?.pakend || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="pakend" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {(this.state.modal.data.type === "production" && !(this.state.modal.data?.pakend || []).length) && <a className="add-item" id="pakend" onClick={this.toggleModal}>Lisa pakend</a>}
                                <h1>Alus</h1>
                                {(this.state.modal.data?.alus || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="alus" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !(this.state.modal.data?.alus || []).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="alus" onClick={this.toggleModal}>Lisa alus</a>}
                                <h1>Pakkematerjal</h1>
                                {(this.state.modal.data?.pm || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        <p>{this.state.stock[key]?.name}</p>
                                        <span parenttype="pm" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                {((this.state.modal.data.type === "production" && !(this.state.modal.data?.pm || []).length) || this.state.modal.data.type === "processor") && <a className="add-item" id="pm" onClick={this.toggleModal}>Lisa pakkematerjal</a>}
                                <h1>Töölised</h1>
                                {(this.state.modal.data?.workers || []).map(key => {
                                    return <span className="jobitem" key={key}>
                                        {this.state.workers[key] ? <p>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</p> :
                                        <p>{this.state.teams[key]?.name || "-"}</p>}
                                        <span parenttype="workers" id={key} onClick={this.removeJobItem}>&times;</span>
                                    </span>
                                })}
                                <a className="add-item" id="workers" onClick={this.toggleModal}>Lisa töölised</a>
                                <h1>Tasustamine</h1>
                                <select id="tas" value={this.state.jobs?.[this.state.modal.id]?.tas || ""} onChange={this.onChangeJobModal} disabled>
                                    <option value=""></option>
                                    <option value="1">Tükitöö</option>
                                    <option value="0">Tunnitöö</option>
                                </select>
                                {this.state.modal.addItem &&
                                <div className="add-item-modal">
                                    <div className={`add-item-modal-container${this.state.modal.addItem.type === "workers" ? " worker-selection" : ""}`}>
                                        <span className="modal-close" onClick={this.closeModal}>&times;</span>
                                        <h1>{this.state.modal.addItem.type === "product" ? "Toodang" : this.state.modal.addItem.type === "pakend" ? "Pakend" : this.state.modal.addItem.type === "alus" ? "Alus" : this.state.modal.addItem.type === "pm" ? "Pakkematerjal" : this.state.modal.addItem.type === "silt" ? "Silt" : ""}</h1>
                                        <input value={this.state.modal.addItem.filter || ""} onChange={this.onChangeAddItemFilter} />
                                        {this.state.modal.addItem?.type === "workers" && <div className="type-selection">
                                            <span className={`left${this.state.modal.selected === "left" ? " selected" : ""}`} id="left" onClick={this.changeAddItemType}>Töötajad</span>
                                            <span className={`right${this.state.modal.selected === "right" ? " selected" : ""}`} id="right" onClick={this.changeAddItemType}>Meeskonnad</span>
                                        </div>}
                                        <div className="item-selection">
                                            {this.state.modal.addItem?.type === "workers" &&
                                            (this.state.modal.selected === "left" ? Object.keys(this.state.workers).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!(this.state.workers[key].firstName + " " + this.state.workers[key].lastName).toLowerCase().includes(filter.toLowerCase())) return
                                                return <span className={`item-selection-worker${this.state.modal.addItem.temp?.includes(key) ? " active" : ""}`} id={key} key={key} onClick={this.onChangeJobModal}>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</span>
                                            }) : Object.keys(this.state.teams).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!(this.state.teams[key].name).toLowerCase().includes(filter.toLowerCase())) return
                                                return <span className={`item-selection-worker${this.state.modal.addItem.temp?.includes(key) ? " active" : ""}`} id={key} key={key} onClick={this.onChangeJobModal}>{this.state.teams[key].name}</span>
                                            })) ||
                                            Object.keys(this.state.stock).map(key => {
                                                let filter = this.state.modal.addItem.filter
                                                if (!this.state.stock[key]?.name.toLowerCase().includes(filter.toLowerCase())) return
                                                if (this.state.modal.addItem.type === "silt" && this.state.stock[key].type !== "Sildid") return
                                                if ((this.state.modal.addItem.type === "pakend" || this.state.modal.addItem.type === "alus" || this.state.modal.addItem.type === "pm") && this.state.stock[key].type !== "Pakkevahendid") return
                                                if (this.state.modal.addItem.type === "product" && (this.state.stock[key].type === "Sildid" || this.state.stock[key].type === "Pakkevahendid")) return
                                                return <span id={key} key={key} onClick={this.onChangeJobModal}>{`${this.state.stock[key]?.name}${this.state.stock[key].type === "Sildid" ? " (silt)" : ""}`}</span>
                                            })}
                                        </div>
                                        {this.state.modal.addItem.type === "workers" && <a className="submit" onClick={this.submitWorkers}>Salvesta</a>}
                                    </div>
                                    {this.state.modal.addItem.countModal && <div className="count-modal">
                                        <div className="count-modal-container">
                                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                                            <h1>{this.state.stock[this.state.modal.addItem.item]?.name}</h1>
                                            <span className="count-label">Kogus:</span>
                                            <input type="number" id="add-item-count" />
                                            <a className="add-side-item" onClick={this.onChangeJobModal}>Kõrvaltoode</a>
                                            <a className="add-main-item" onClick={this.onChangeJobModal}>Lisa</a>
                                        </div>
                                    </div>}
                                </div>}
                                <h1>Kommentaarid</h1>
                                <textarea id="comments" value={this.state.modal.data.comments} onChange={this.onChangeJobModal} />
                            </div>
                            <div className="edit-job-right">
                                <h1>Tellimus: <b>{this.state.modal.data?.client+this.state.modal.data?.number}</b></h1>
                                <h1>Aktiivne</h1>
                                <input className="switchbutton" type="checkbox" checked={this.state.modal.data.active} onChange={this.toggleActive}/>
                                <h1>Aktiivsed töölised</h1>
                                {this.state.modal.data?.activeWorkers.map(key => {
                                    return <p className="active-worker" key={key} id={key} onClick={this.finishRest}>{this.state.workers[key] ? `${this.state.workers[key].firstName} ${this.state.workers[key].lastName}` : `${this.state.teams[key].name}`}</p>
                                })}
                                <h1>Töötajate panus</h1>
                                <div className="worker-contribution">
                                    {Object.keys(this.state.modal.data?.progress).sort((key) => {
                                        if (key === "ovr") return -1
                                        else return 1
                                    }).map(key => {
                                        return <div key={key}>
                                            <h2>{key === "ovr" ? "Kokku" : (this.state.workers[key] ? `${this.state.workers[key].firstName} ${this.state.workers[key].lastName}` : `${this.state.teams[key]?.name}`)}</h2>
                                            {Object.keys(this.state.modal.data.progress[key]).map(key1 => {
                                                return <div key={key1}>
                                                    <p>{this.state.stock[key1]?.name} - </p>
                                                    <span id={key} onClick={this.toggleAlused}> {this.state.modal.data.progress[key][key1]}</span>
                                                </div>
                                            })}
                                            {this.state.toggledAlused?.includes(key) &&
                                            <div className="worker-contribution-alused">
                                                {this.state.modal.data.alused?.[key]?.map(alus => {
                                                    return <p>{alus}</p>
                                                })}
                                            </div>}
                                        </div>
                                    })}
                                </div>
                            </div>
                            {!this.state.modal.data?.activeWorkers?.length ? <a className="delete" onClick={this.deleteJob}>Kustuta</a> : <></>}
                            <a className="submit" onClick={this.save}>Salvesta</a>
                            {this.state.finishRestModal && <div className="confirm-modal">
                                <div className="confirm-modal-container">
                                    <h1 className="text">{this.state.teams[this.state.finishRestModal]?.name || `${this.state.workers[this.state.finishRestModal].firstName} ${this.state.workers[this.state.finishRestModal].lastName}`} aluse lõpetamine.</h1>
                                    <div className="confirmation-buttons">
                                        <a className="finish" onClick={this.finishRestConfirm}>Lõpeta</a>
                                        <a className="cancel" onClick={this.finishRestConfirm}>Tühista</a>
                                        <a className="back" onClick={this.finishRestConfirm}>Tagasi</a>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                    </div> ||
                    this.state.modal.view === "report" &&
                    <div className="reportmodal">
                        {this.state.view === "workers" &&
                        <div className="reportmodal-content">
                            <span className="reportmodal-close" id="reportmodal" onClick={this.closeModal}>&times;</span>
                            <div className="modal-header">
                                <div className="datefilters">
                                    <h1>Alates</h1>
                                    <input type="date" id="from" onChange={this.onChange}/>
                                    <h1>Kuni</h1>
                                    <input type="date" id="to" onChange={this.onChange}/>
                                </div>
                                <div className="summary">
                                    <h1>Kokkuvõte:</h1>
                                    <div className="summary-last2">
                                        <p>Eelmine kuu</p>
                                        <p>{this.state.summary2 || "00:00:00"}</p>
                                    </div>
                                    <div className="summary-last">
                                        <p>See kuu</p>
                                        <p>{this.state.summary || "00:00:00"}</p>
                                    </div>
                                </div>
                                <a className="exportbutton" onClick={this.exportCSV}><i className="fas fa-file-export"></i></a>
                            </div>
                            <div className="report">
                                <div className="row row-sticky">
                                    <h1 className="name name-header">Päev</h1>
                                    <h1 className="duration duration-header">Tööl viibitud aeg</h1>
                                    <div className="times">
                                        <h1 className="timeheader">Sisse-välja registreerimise ajad</h1>
                                    </div>
                                </div>
                                {Array(((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days') : 62).fill(0,0,((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).diff(moment(this.state.filters.from).subtract(1, 'day'), 'days') : 62).map((key, j) => {
                                    
                                    if ((this.state.filters.from && this.state.filters.to) && (this.state.filters.from > this.state.filters.to)) return
                                    if ((!this.state.filters.to || !this.state.filters.from) || this.state.filters.to < this.state.filters.from) {
                                        if (moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().startOf('month').format("YYYY-MM-DD") && moment().subtract(j, 'days').startOf('month').format("YYYY-MM-DD") !== moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")) return
                                    }
                                    
                                    let day = ((this.state.filters.to && this.state.filters.from) && this.state.filters.to >= this.state.filters.from) ? moment(this.state.filters.to).subtract(j, 'days') : moment().subtract(j, 'days') 
                                    let times = this.state.workers[this.state.modal.id]?.logs?.[day.format("YYYY-MM-DD")]?.times
                                    let seconds = 0
                                    let minutes = 0
                                    let hours = 0

                                    let limited = []
                                    let timesArr = []

                                    if (times?.[0]?.split(":")[0] === "end") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                seconds += moment(`${day.format("YYYY-MM-DD")} ${times[0].split(":")[1]}:${times[0].split(":")[2]}:${times[0].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss").diff(moment(day).startOf('day'), 'seconds')
                                                limited.push(`${times[0].split(":")[1]}:${times[0].split(":")[2]}`)
                                            }
                                            if (times[i*2+1]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+2]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}:${times[i*2+2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+2].split(":")[1]}:${times[i*2+2].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    } else if (times?.[0]?.split(":")[0] === "start") {
                                        times.forEach((key, i) => {
                                            if (i === 0) {
                                                limited.push(0)
                                            }
                                            if (times[i*2]) {
                                                let a = moment(`${day.format("YYYY-MM-DD")} ${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}:${times[i*2].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                if (times[i*2+1]) {
                                                    let b = moment(`${day.format("YYYY-MM-DD")} ${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}:${times[i*2+1].split(":")[3]}`, "YYYY-MM-DD HH:mm:ss")
                                                    seconds += b.diff(a, 'seconds')
                                                    timesArr.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                    timesArr.push(`${times[i*2+1].split(":")[1]}:${times[i*2+1].split(":")[2]}`)
                                                } else {
                                                    let b = j === 0 ? moment(`${day.format("YYYY-MM-DD")} ${this.state.time}`, "YYYY-MM-DD HH:mm:ss") : moment(day).endOf('day')
                                                    seconds += b.diff(a, 'seconds')
                                                    limited.push(`${times[i*2].split(":")[1]}:${times[i*2].split(":")[2]}`)
                                                }
                                            }
                                            if (i+1===times.length) {
                                                minutes = Math.floor(seconds / 60)
                                                seconds = seconds%60
                                                hours = Math.floor(minutes/60)
                                                minutes = minutes%60
                                            }
                                        })
                                    }
                                    
                                    return <div className="row" key={j}>
                                        <h1 className="name">{`${day.format("DD")}. ${this.months.est[day.format('M')-1]}`}</h1>
                                        <h1 className="duration">{`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</h1>
                                        <div className="times">
                                            {!!limited[0] && <h1 className="time" date={day.format("YYYY-MM-DD")} to={0} onClick={this.openTime}>{`--:-- - ${limited[0]}`}</h1>}
                                            {timesArr.map((key, i) => {
                                                if (!timesArr[i*2]) return
                                                return <h1 className="time" key={i} date={day.format("YYYY-MM-DD")} from={limited[0] ? i*2+1 : i*2} to={limited[0] ? i*2+2 : i*2+1} onClick={this.openTime}>{`${timesArr[i*2]} - ${timesArr[i*2+1]}`}</h1>
                                            })}
                                            {!!limited[1] && <h1 className="time" date={day.format("YYYY-MM-DD")} from={times.length-1} onClick={this.openTime}>{`${limited[1]} - --:--`}</h1>}
                                            <i className="fas fa-plus" date={day.format("YYYY-MM-DD")} onClick={this.openTime}/>
                                        </div>
                                    </div>
                                })}
                            </div>
                            {/* <div className="backbutton" onClick={this.back}>
                                <i className="fas fa-arrow-left"/>
                                <h1>Tagasi</h1>
                            </div> */}
                        </div> ||
                        this.state.view === "jobs" &&
                        <div className="reportmodal-content">
                            <span className="reportmodal-close" id="teammodal" onClick={this.closeModal}>&times;</span>
                            <h1 className="modal-heading">{this.state.teams?.[this.state.modal.id]?.name}</h1>
                            <div className="team-dates">
                                <h1>Alates</h1>
                                <input type="date" id="from" onChange={this.onChange}/>
                                <h1>Kuni</h1>
                                <input type="date" id="to" onChange={this.onChange}/>
                                <a onClick={this.filterTeamHistory}>Filtreeri</a>
                            </div>
                            <div className="team-history">
                                {Object.keys(this.state.teams[this.state.modal.id]?.history || {}).reverse().map(key => {
                                    const historyItem = this.state.teams[this.state.modal.id].history[key]
                                    let date = moment(key, "YYYY-MM-DD HH:mm").format("DD/MM/YY HH:mm")
                                    // let {from, to} = this.state.filters
                                    // if ((from || to) && (from > moment(date, "DD/MM/YY HH:mm").format("YYYY-MM-DD") || to < moment(date, "DD/MM/YY HH:mm").format("YYYY-MM-DD"))) return
                                    return <div className="team-history-item" key={key}>
                                        <h1>{date}</h1>
                                        <h1>{historyItem.restNr}</h1>
                                        <h1>{historyItem.order}</h1>
                                        <h1>{historyItem.team?.length}</h1>
                                        <h1 id="changeteam" className="change" dateID={key} onClick={this.toggleModal}>Töötajad</h1>
                                    </div>
                                })}
                            </div>
                            {this.state.modal.changeTeam && <div className="membersmodal">
                                <div className="membersmodal-container">
                                    <h1 className="membersmodal-heading">{this.state.teams[this.state.modal.id].history[this.state.modal.changeTeam.id]?.restNr}</h1>
                                    <span className="reportmodal-close" id="membersmodal" onClick={this.closeModal}>&times;</span>
                                    {this.state.modal.changeTeam.toggled && <div className="membersmodal-content">
                                        <input value={this.state.modal.changeTeam.filter || ""} onChange={this.filterTeamMembers}/>
                                        <div className="membersmodal-emp-add">
                                            {Object.keys(this.state.workers).map(key => {
                                                let filter = this.state.modal.changeTeam.filter || ""
                                                if (!(this.state.workers[key].firstName + " " + this.state.workers[key].lastName).toLowerCase().includes(filter.toLowerCase())) return
                                                if (this.state.modal.changeTeam.team.includes(key)) return
                                                return <a id={key} key={key} onClick={this.addTeamMember}>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</a>
                                            })}
                                        </div>
                                    </div> ||
                                    <div className="membersmodal-content">
                                        <div className="membersmodal-emp">
                                            {this.state.modal.changeTeam.team.map(key => {
                                                return <div key={key}>
                                                    <span>{this.state.workers[key].firstName} {this.state.workers[key].lastName}</span>
                                                    <a id={key} onClick={this.removeTeamMember}>&times;</a>
                                                </div>
                                            })}
                                        </div>
                                    </div>}
                                    <a className="membersmodal-toggle" id="changeteam-toggle" onClick={this.toggleModal}>{this.state.modal.changeTeam.toggled && "Tagasi" || "Lisa"}</a>
                                    {!this.state.modal.changeTeam.toggled && <a className="membersmodal-save" onClick={this.saveTeam}>Salvesta</a>}
                                </div>
                            </div>}
                        </div>}
                        {this.state.modal.date &&
                        <div className="datemodal">
                            <div className="datemodal-content">
                                <span className="datemodal-close" modal="datemodal" onClick={this.closeModal}>&times;</span>
                                <div className="modal-header1">
                                    <h1>Muuda aega</h1>
                                </div>
                                <div className="modal-content1">
                                    <div className="date-edit-from">
                                        <h1>Algus</h1>
                                        <input type="date" id="startdate" defaultValue={this.state.modal.date} />
                                        <input type="time" id="starttime" defaultValue={`${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.from]?.split(":")[1]}:${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.from]?.split(":")[2]}`} />
                                    </div>
                                    <div className="date-edit-to">
                                        <h1>Lõpp</h1>
                                        <input type="date" id="enddate" defaultValue={this.state.modal.date} />
                                        <input type="time" id="endtime" defaultValue={`${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.to]?.split(":")[1]}:${this.state.workers[this.state.modal.id]?.logs?.[this.state.modal.date]?.times[this.state.modal.data?.to]?.split(":")[2]}`} />
                                    </div>
                                </div>
                                {(this.state.modal.data.from || this.state.modal.data.to) && <a className="button-delete" onClick={this.deleteTime}>Kustuta sissekanne</a>}
                                <a className="button-edit" onClick={this.saveTime}>Salvesta</a>
                            </div>
                        </div>}
                    </div> ||
                    this.state.modal.view === "workerdetails" &&
                    <div className="workerdetails">
                        <div className="workerdetails-content">
                            {this.state.modal?.logDate ?
                            <div className="log">
                                <span className="workerdetails-close" modal="datemodal" onClick={this.toggleLogs}>&times;</span>
                                <h1 className="log-heading">{this.state.workers[this.state.modal.id].firstName} {this.state.workers[this.state.modal.id].lastName} - {moment(this.state.modal.logDate).format("DD/MM/YYYY")}</h1>
                                <p className="log-helper">{this.state.logsHelp}</p>
                                <div className="log-content">
                                    {Object.keys(this.state.workers[this.state.modal.id].driverLogs[this.state.modal.logDate] || {}).map(key => {
                                    let log = this.state.workers[this.state.modal.id].driverLogs[this.state.modal.logDate][key]
                                    let type = ""
                                    if (log.type === 1) type = "Koorma vastuvõtt"
                                    else if (log.type === 2) type = "Lõhkumise alustamine"
                                    else if (log.type === 3) type = "Palgi äravedu"
                                    else if (log.type === 4) type = "Resti äravedu"
                                    else if (log.type === 5) type = "Koorma lõpetamine"
                                    else if (log.type === 6) type = "Resti lisamine"
                                    else if (log.type === 7) type = "Resti muutmine"
                                    else if (log.type === 8) type = "Resti lõpetamine"
                                    else if (log.type === 9) type = "Osaline müük"
                                    else if (log.type === 10) type = "Kuivati"
                                    else if (log.type === 11) type = "Tellimuse muutmine"
                                    else if (log.type === 12) type = "Tellimuse lõpetamine"
                                    if (log.type < 10) return <div className="log-row" onMouseOver={this.showHelp} onMouseOut={this.hideHelp} key={key}>
                                        <h1>{key}</h1>
                                        <h1>{type}</h1>
                                        <h1 help={log.type < 6 ? "Koorma number" : "Resti number"}>{log.type < 6 ? log.koorem : log.restNr}</h1>
                                        {log.type === 2 && <>
                                            <h1 help="Lõhkuja">{log.chopper}</h1>
                                            <h1 help="Materjal">{log.material}</h1>
                                            <h1 help="Sortiment">{log.sortiment}</h1>
                                        </>}
                                        {log.type === 3 && <>
                                            <h1 help="Palk">{log.log}</h1>
                                            <h1 help="Kogus">{log.count}</h1>
                                        </>}
                                        {log.type === 4 && <h1 help="Resti number">{log.restNr}</h1>}
                                        {(log.zone || log.location) && <h1 help="Asukoht">{log.oldLocation && `${log.oldLocation.mainzone} - ${log.oldLocation.subzone} → `}{log.zone ? log.zone : `${log.location.mainzone} - ${log.location.subzone}`}</h1>}
                                        {(log.type === 4 || log.type > 5) && <h1 help="Artikkel">{log.article}</h1>}
                                        {(log.type > 5 && log.type < 9) && <h1 help="Tootmisjärk">{log.phase}</h1>}
                                        {log.amount && <h1 help="Tükiarv">({log.amount})</h1>}
                                    </div>
                                    else return <div className="log-row" onMouseOver={this.showHelp} onMouseOut={this.hideHelp} key={key}>
                                        <h1>{key}</h1>
                                        <h1>{type}</h1>
                                        {(log.type === 10) && <h1>{log.action === "start" ? "Alustamine" : "Lõpetamine"}</h1>}
                                        {(log.type === 10) && <h1 help="Kamber">{log.chamber}</h1>}
                                        {(log.type === 11) && <h1>{log.action === "add" ? "Lisamine" : "Eemaldamine"}</h1>}
                                        {(log.type === 11) && <h1 help="Tellimus">{log.shipment}</h1>}
                                        {(log.type === 11) && <h1 help="Alus">{log.unit}</h1>}
                                        {(log.type === 12) && <h1 help="Arve number">{log.invoice}</h1>}
                                        {(log.type === 12) && <h1 help="Tellimuse tüüp">{log.shipType === "myyk" ? "Müük" : log.shipType === "haljala" ? "Haljala" : "ERROR"}</h1>}
                                    </div>
                                    })}
                                </div>
                            </div> : 
                            <>
                                <span className="workerdetails-close" modal="datemodal" onClick={this.toggleModalWorkerdetails}>&times;</span>
                                <h1 className="workerdetails-heading">{this.state.workers[this.state.modal.id].firstName} {this.state.workers[this.state.modal.id].lastName}</h1>
                                <select className="year" value={this.state.modal.year || moment().format("YYYY")} onChange={this.onChangeWDModal}>
                                    {Array(Number(moment().format("YYYY"))-2020).fill(0).map((key, i) => {
                                        return <option key={i} value={Number(moment().format("YYYY"))-i}>{Number(moment().format("YYYY"))-i}</option>
                                    })}
                                </select>
                                <select className="month" value={this.state.modal.month || moment().format("M")-1} onChange={this.onChangeWDModal}>
                                    {this.months.est.map((key, i) => {
                                        return <option key={i} value={i}>{key}</option>
                                    })}
                                </select>
                                {this.state.workers[this.state.modal.id].type !== "forklift" && <a className="exportbutton" onClick={this.exportCSV}>Ekspordi</a>}
                                {this.state.workers[this.state.modal.id].type === "forklift" ? 
                                <div className="workerdetails-table">
                                    {Array(moment(`${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`).daysInMonth()).fill(0).map((key, i) => {
                                        let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
                                        let today = moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")
                                        return <div className="workerdetails-row" key={today}>
                                            <h1 className="sticky">{moment(today).format("DD/MM/YYYY")}</h1>
                                            <a id={today} onClick={this.toggleLogs}>{this.state.workers[this.state.modal.id].driverLogs?.[today] && `Logid (${Object.keys(this.state.workers[this.state.modal.id].driverLogs?.[today]).length})`}</a>
                                        </div>
                                    })}
                                </div> :
                                <div className="workerdetails-table">
                                    <div className="workerdetails-row">
                                        <h1 className="sticky"></h1>
                                        {this.state.modal.items.map(key => {
                                            return <h1 key={key}>{this.state.stock[key]?.name}</h1>
                                        })}
                                        <h1>Tunnitöö</h1>
                                    </div>
                                    {(() => {
                                        let month = `${this.state.modal.year || moment().format("YYYY")}-${this.state.modal.month ? Number(this.state.modal.month)+1 : moment().format("M")}`
                                        let sum = []
                                        let hsum = 0
                                        return Array(moment(`${month}`).daysInMonth()+1).fill(0).map((key, i) => {
                                            if (i === moment(`${month}`).daysInMonth()) {
                                                sum.push(hsum)
                                                return <div className="workerdetails-row" key="last">
                                                    <h1 className="sticky">Kokku</h1>
                                                    {sum.map(key => {
                                                        return <h1>{round(key)}</h1>
                                                    })}
                                                </div>
                                            }
                                            let today = moment(month).startOf('month').add(i, 'days').format("YYYY-MM-DD")
                                            hsum += this.state.workers[this.state.modal.id]?.hourly?.[today]?.hours || 0
                                            return <div className="workerdetails-row" key={today}>
                                                <h1 className="sticky">{moment(today).format("DD/MM/YYYY")}</h1>
                                                {this.state.modal.items.map((key, i) => {
                                                    if (!sum[i]) sum[i] = 0
                                                    sum[i] += this.state.workers[this.state.modal.id].work[today]?.[key] || 0
                                                    return <h1 key={i}>{round(this.state.workers[this.state.modal.id].work[today]?.[key]) || 0}</h1>
                                                })}
                                                <h1 id={today} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>{this.state.workers[this.state.modal.id]?.hourly?.[today]?.hours}</h1>
                                                {this.state.workers[this.state.modal.id]?.hourly?.[today]?.comment && <div className={`hourlycomment${Number(moment(today).format("DD")) > 15 ? " hourlycomment-bottom": ""}`} style={{display: this.state.hover === today ? 'block' : 'none'}}>{this.state.workers[this.state.modal.id]?.hourly?.[today]?.comment}</div>}
                                            </div>
                                        })
                                    })()}
                                </div>}
                            </>}
                        </div>
                    </div> ||
                    this.state.modal.view === "export" &&
                    <div className="exportmodal">
                        <div className="exportmodal-content">
                            <span className="workerdetails-close" modal="datemodal" onClick={this.toggleExportModal}>&times;</span>
                            <select className="year" value={this.state.modal.year || moment().format("YYYY")} onChange={this.onChangeWDModal}>
                                {Array(Number(moment().format("YYYY"))-2020).fill(0).map((key, i) => {
                                    return <option value={Number(moment().format("YYYY"))-i}>{Number(moment().format("YYYY"))-i}</option>
                                })}
                            </select>
                            <select className="month" value={this.state.modal.month || moment().format("M")-1} onChange={this.onChangeWDModal}>
                                {this.months.est.map((key, i) => {
                                    return <option value={i}>{key}</option>
                                })}
                            </select>
                            <a className="exportbutton" onClick={this.calculateExport}>Ekspordi</a>
                        </div>
                    </div>
                    }
                </div>
            </main>
        )
    }
}

export default Manage