import React from 'react'

class Notifications extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.state = {
            notifications: {}
        }
    }

    componentDidMount() {

    }

    render() {
        return <main>
            <div className="notifications">
                Notifications
            </div>
        </main>
    }
}

export default Notifications