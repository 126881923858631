import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'

class Inbox extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            feedback: {
                view: "feedback",
                filters: {
                    active: false
                },
                data: [],
                modalData: null,
                deleteConfirmation: null
            }
        }

        this.zebraPrinter = null
    }
    
    getFeedback = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/feedback/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            if (!data || !data?.length) this.setState({feedback: {...this.state.feedback, data: {}}})
            let newdata = {}
            data.forEach((a, i) => {
                newdata = {...newdata, [a._id]: a}
                if (data.length >= i+1) this.setState({feedback: {...this.state.feedback, data: newdata}})
            })
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        this.getFeedback()
        this.socket.on("change_feedback", this.getFeedback)
    }
    
    componentWillUnmount() {
        this.socket.off("change_feedback", this.getFeedback)
    }

    route = e => {
        e.preventDefault()
        this.setState({feedback: {...this.state.feedback, view: e.target.id}})
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({feedback: {...this.state.feedback, filters: {...this.state.feedback.filters, active: !this.state.feedback.filters.active}}})
    }

    clear = e => {
        this.setState({feedback: {...this.state.feedback, filters: {active: true}}})
    }

    onChange = e => {
        e.preventDefault()
        this.setState({feedback: {...this.state.feedback, filters: {...this.state.feedback.filters, [e.target.id]: e.target.value}}})
    }

    star = e => {
        e.preventDefault()
        this.socket.emit("saveFB", {id: e.target.id, type: "star"})
    }

    delete = e => {
        e.preventDefault()
    }

    viewMessage = e => {
        e.preventDefault()
        const feedback = this.state.feedback
        if (feedback.data[e.target.id]?.seen === false) {
            this.socket.emit("saveFB", {id: e.target.id, type: "seen"})
        }
        this.setState({feedback: {...feedback, modalData: feedback.data[e.target.id]}})
    }

    changeNotes = e => {
        e.preventDefault()
        this.setState({feedback: {...this.state.feedback, modalData: {...this.state.feedback.modalData, notes: e.target.value}}})
    }

    save = e => {
        e.preventDefault()
        this.socket.emit("saveFB", {id: this.state.feedback.modalData._id, type: "save", content: this.state.feedback.modalData.notes})
        this.setState({feedback: {...this.state.feedback, modalData: null}})
    }
    
    archive = e => {
        e.preventDefault()
        this.socket.emit("saveFB", {id: this.state.feedback.modalData._id, type: "archive"})
        this.setState({feedback: {...this.state.feedback, modalData: null}})
    }

    delete = e => {
        e.preventDefault()
        
        if (this.state.feedback.deleteConfirmation) {
            if (e.target.id === "yes") this.socket.emit("saveFB", {id: this.state.feedback.deleteConfirmation, type: "delete"})
            this.setState({feedback: {...this.state.feedback, deleteConfirmation: null}})
        } else {
            this.setState({feedback: {...this.state.feedback, deleteConfirmation: e.target.id}})
        }
    }

    closeModal = e => {
        e.preventDefault()
        this.setState({feedback: {...this.state.feedback, modalData: null}})
    }

    render() {
        const feedback = this.state.feedback
        const modalData = feedback.modalData
        return (
            <main>
                <div className="inbox">
                    <div className="inbox-container">
                        <div className="inbox-content">
                            <div className={`header${feedback.filters.active ? " header--open" : ""}`}>
                                <a className={`linkbutton${feedback.filters.active ? " linkbutton--active" : ""}`} onClick={this.toggleFilters}><i className="fas fa-angle-right"/> Filtreeri</a> 
                                <div className="header-nav">
                                    <a className={`header-nav--button${feedback.view === "feedback" ? " header-nav--button--active": ""}`} id="feedback" onClick={this.route}>Tagasiside</a>
                                    <a className={`header-nav--button${feedback.view === "archive" ? " header-nav--button--active": ""}`} id="archive" onClick={this.route}>Arhiiv</a>
                                </div>
                                {feedback.filters.active && <a className="linkbutton linkbutton--clear" onClick={this.clear}>Tühjenda filtrid</a>}
                                {/* {this.state.feedback.filters.active && */}
                                <div className="filters">
                                    <div className="filter">
                                        <h1>Pealkiri</h1>
                                        <input type="text" id="title" value={feedback.filters.title || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Sisu</h1>
                                        <input type="text" id="content" value={feedback.filters.content || ""} onChange={this.onChange} />
                                    </div>
                                    <div className="filter">
                                        <h1>Tähtis</h1>
                                        <select id="important" value={feedback.filters.important || ""} onChange={this.onChange}>
                                            <option value="2">Mõlemad</option>
                                            <option value="1">Jah</option>
                                            <option value="0">Ei</option>
                                        </select>
                                    </div>
                                    <div className="datefilter">
                                        <h1>Saatmise kuupäev</h1>
                                        <input type="date" id="sentfrom" value={feedback.filters.sentfrom || ""} onChange={this.onChange} />
                                        <input type="date" id="sentto" value={feedback.filters.sentto || ""} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="feedback-container">
                            {Object.keys(feedback.data).map((key, i) => {
                                const filters = feedback.filters
                                let msg = feedback.data[key]
                                if (feedback.view === "feedback" && msg.archived) return
                                if (feedback.view === "archive" && !msg.archived) return
                                if (filters.important === "0" && msg.important) return
                                if (filters.important === "1" && !msg.important) return
                                if (filters.title && !(msg.title||"").toLowerCase().includes(filters.title.toLowerCase())) return
                                if (filters.content && !(msg.content||"").toLowerCase().includes(filters.content.toLowerCase())) return
                                let sentDate = moment(msg.createdAt).format("YYYY-MM-DD")
                                if (filters.sentfrom > sentDate || filters.sentto < sentDate) return
                                return <div className="message" key={i}>
                                    <span className="star" id={key} onClick={this.star}>{msg.important && <i className="fas fa-star important"/> || <i className="far fa-star"/>}</span>
                                    <h1 className={`title${msg.seen ? "" : " unseen"}`} id={key} onClick={this.viewMessage}>{msg.title} {msg.notes && <i className="far fa-comment-dots"/>}</h1>
                                    <h1 className={`timestamp${msg.seen ? "" : " unseen"}`}>{moment(msg.createdAt).format("DD/MM/YYYY HH:mm:ss")}</h1>
                                    <span className="delete" id={key} onClick={this.delete}><i className="fas fa-trash-alt"/></span>
                                </div>
                            })}
                            </div>
                        </div>
                    </div>
                    {modalData && <div className="fbmodal">
                        <div className="fbmodal-content">
                            <span className="modal-close" onClick={this.closeModal}>&times;</span>
                            <h1 className="modal-header">{modalData.title || "-Pealkirjata-"}</h1>
                            <div className="modal-content">
                                <p>{modalData.content || "-Sisutu-"}</p>
                            </div>
                            <div className="modal-notes">
                                <textarea id="modal-notes" value={modalData.notes} placeholder="Märkmed" onChange={this.changeNotes} />
                            </div>
                            <h1 className="modal-archive" onClick={this.archive}>{feedback.view === "feedback" ? "Arhiveeri" : "Eemalda arhiivist"}</h1>
                            <a className="modal-submit" onClick={this.save}>Salvesta</a>
                        </div>
                    </div>}
                    {feedback.deleteConfirmation && <div className="fbmodal">
                        <div className="fbmodal-content1">
                            <h1>Kas oled kindel, et soovid selle jäädavalt kustutada?</h1>
                            <a className="modal-confirm" onClick={this.delete} id="yes">Jah</a>
                            <a className="modal-confirm modal-confirm--no" onClick={this.delete} id="no">Ei</a>
                        </div>
                    </div>}
                </div>
            </main>
        )
    }
}

export default Inbox