import React from 'react'
import {history} from '../routers/AppRouter'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.props = props
    }

    login = event => {
        event.preventDefault()
          
          fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
                method: 'POST',
                body: JSON.stringify({
                    username: document.getElementById('username').value,
                    password: document.getElementById('password').value
                }),
                credentials: 'include',
                headers: {"Content-Type": "application/json"}
            }).then(res => {
                if (res.status === 200) {
                    history.push('/management/overview')
                } else {
                    return res.json()
                }
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log(data['error'])
                }
            }).catch(err => {
                console.log(err)
            })
      }

    render() {
        return (
            <section className="login-page">
                <div className="login-container">
                    <div className="login-header">
                        <h1>Logi Sisse</h1>
                    </div>
                    <div className="login">
                        <div className="login-input">
                            <i className="fas fa-user"/>
                            <input type="text" id="username" key="username" placeholder="Kasutajatunnus"/>
                        </div>
                        <div className="login-input">
                            <i className="fas fa-lock"/>
                            <input type="password" id="password" key="password" placeholder="Salasõna"/>
                        </div>
                        <a className="login-btn" onClick={this.login}>Sisene</a>
                    </div>
                </div>
            </section>
        )
    }
}

export default Login