import React from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'

import {translate} from '../utils/utils'

class Workspace extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.socket = socketIOClient(`${process.env.REACT_APP_SERVER_URL}/`, {
            withCredentials: true
        })

        this.state = {
            _id: "",
            cardID: "",
            employees: {},
            time: moment().format("HH:mm:ss"),
            notif: {},
            job: {},
            view: null,
            stock: {},
            modal: null,
            errorCode: 0,
            teams: {},
            team: {},
            allWorkers: [],
            search: "",
            finishJob: {
                productCount: {}
            },
            loading: false,
            printList: {
                employee: null,
                list: null,
                number: null
            },
            endData: null,
            lang: 'est'
        }
    }
    
    onCardInsert = e => {
        if (this.state.view === "job" || this.state.view === "finish" || this.state.view === "team") return
        const date = moment().format("YYYY-MM-DD")
        const time = moment().format("HH:mm:ss")
        if (e.key === "F9") {
            this.setState({view: "print"})
        } else if (e.key === "Enter") {
            if (this.state.view === "print") {
                fetch(`${process.env.REACT_APP_SERVER_URL}/print/list?id=${this.state.cardID}`, {
                    credentials: 'include'
                }).then(res => {
                    return res.json()
                }).then(data => {
                    this.setState({printList: {employee: data.id, list: data.doc, number: null}, cardID: data.id ? this.state.cardID : ""})
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.socket.emit("enterWorkspace", {cardID: this.state.cardID, date, time})
                this.getNames()
            }
        } else {
            this.setState({cardID: this.state.cardID + e.key})
        }
    }

    getTeam = data => {
        if (data?.error || !data) return
        if (data.team) data.team.forEach((team, i) => this.setState({teams: {...this.state.teams, [team._id]: {...team}}}))
    }

    // getEmployee = data => {
    //     if (data?.error || !data) return
    //     if (!data.length) {
    //         this.setState({employees: {}})
    //         return
    //     }
    //     data.forEach((item, i) => {
    //         if (i === 0) {
    //             this.setState({employees: {[item._id]: item.firstName + " " + item.lastName}})
    //         } else {
    //             this.setState({employees: {...this.state.employees, [item._id]: item.firstName + " " + item.lastName}})
    //         }
    //         if (i+1 === data.length) {
    //             this.socket.on("get_employee", this.getEmployee)
    //         }
    //     })
    // }

    getStock = data => {
        if (data?.error || !data) return
        if (!data.length) {
            this.setState({stock: {}})
            return
        }
        data.forEach((item, i) => {
            if (i === 0) {
                this.setState({stock: {[item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            } else {
                this.setState({stock: {...this.state.stock, [item.article]: {name: item.name, count: item.count, unit: item.unit, type: item.type}}})
            }
            if (i+1 === data.length) {
                this.socket.off("get_stock", this.getStock)
            }
        })
    }

    changeWorkspace = data => {
        if (data.cardID !== this.state.cardID) return
        
        if (data.error) this.setState({errorCode: data.code, view: "error", loading: false, job: {employeeID: data.employeeID}})
        else if (data.workers) this.setState({team: {name: this.state.teams[data.employeeID].name, workers: data.workers}, allWorkers: data.allWorkers, view: "team", loading: false})
        else {
            let employeeID = data.employeeID
            let teamName = this.state.teams[employeeID]?.name
            data = {...data.data, ongoing: !!data.ongoing}
            // fetch(`${process.env.REACT_APP_SERVER_URL}/image/getone?article=${data.silt[0]}`, {
            //     credentials: 'include'
            // }).then(res => {
            //     return res.json()
            // }).then(image => {
            //     // let img = new Image()
            //     image = 'data:image/png;base64,' + image.image
            // }).catch(err => {
            //     console.log(err)
            // })
            fetch(`${process.env.REACT_APP_SERVER_URL}/image/ext/${data.silt[0]}`, {
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(ext => {
                this.setState({job: {...data, employeeID, ext: ext.ext}, view: "job", team: {name: teamName, workers: {}}, loading: false})
            }).catch(err => {
                console.log(err)
            })
        }
    }

    changeJob = data => {
        if (data.cardID !== this.state.cardID) return
        if (this.state.job.type === "production" && this.state.job.ongoing) {
            // let q = `
            //     ^XA
            //     ^FO75,25^AVN,420,300^FD${data.restNr}
            //     ^FS
            //     ^XZ`
            // this.zebraPrinter.send(q, undefined, err => console.log(err))
            // this.zebraPrinter.send(q, undefined, err => console.log(err))
            // q = `
            //     ^XA
            //     ^FO75,50^BY6,3,10
            //     ^B3N,N,250,Y,N^FD${data.restNr}^FS
            //     ^XZ`
            // this.zebraPrinter.send(q, undefined, err => console.log(err))
            // this.zebraPrinter.send(q, undefined, err => console.log(err))
            let q = `^XA
                ^FO75,10^BY6,3,10
                ^B3N,N,220,N,N^FD${data.restNr}^FS
                ^FO80,210
                ^AVN,200,250^FD${data.restNr}
                ^XZ`
            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.zebraPrinter.send(q, undefined, err => console.log(err))
            this.setState({cardID: "", job: {}, view: "end", team: {}, finishJob: {productCount: {}}, loading: false, endData: {name: this.state.team?.name || this.state.employees?.[this.state.job.employeeID][0], restNr: data.restNr}})
        }
        else this.setState({cardID: "", job: {}, view: null, lang: 'est', team: {}, finishJob: {productCount: {}}, loading: false})
    }

    getNames = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/employee/names`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            let employees = {}
            data.forEach((worker, i) => {
                employees = {...employees, [worker._id]: [worker.firstName + " " + worker.lastName, worker.lang]}
                if (i+1 === data.length) {
                    this.setState({employees})
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount() {
        window.removeEventListener('keydown', this.onCardInsert)
        window.addEventListener('keydown', this.onCardInsert)

        window.BrowserPrint.getDefaultDevice("printer", device => {
            this.zebraPrinter = new window.Zebra.Printer(device)
        }, error => {console.log(error)})

        this.socket.emit("employeeGet", {start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD")})
        this.socket.emit("stockGet", {})
        // this.socket.on("get_employee", this.getEmployee)
        this.socket.emit("teamGet")
        this.socket.on("get_team", this.getTeam)
        this.socket.on("get_stock", this.getStock)
        this.socket.on("change_workspace", this.changeWorkspace)
        this.socket.on("change_job", this.changeJob)
        this.socket.on("print", this.print)

        this.getNames()
    
        fetch(`${process.env.REACT_APP_SERVER_URL}/settings/get`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(settings => {
            this.setState({settings})
        }).catch(err => {
            console.log(err)
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onCardInsert)
        this.socket.off("change_workspace", this.changeWorkspace)
        // this.socket.off("get_employee", this.getEmployee)
        this.socket.off("get_stock", this.getStock)
        this.socket.off("change_job", this.changeJob)
        this.socket.off("get_team", this.getTeam)
        this.socket.off("print", this.print)
    }

    accept = e => {
        e.preventDefault()
        if (this.state.loading) return
        this.setState({loading: true})
        this.socket.emit("start_job", {cardID: this.state.cardID, job: this.state.job._id, isTeam: this.state.team.name ? true : false})
    }

    toggleModal = e => {
        e.preventDefault()

        if (e.target.className === "decline-job") this.setState({modal: "decline"})
        else if (e.target.className === "cancel-job") this.setState({modal: "cancel"})
        else if (e.target.className === "accept-job") {
            if (this.state.job.type === "production") this.setState({modal: null, view: "finish"})
            else this.setState({modal: "finish"})
        }
    }

    confirmation = e => {
        e.preventDefault()
        
        if (this.state.modal === "decline") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") this.setState({modal: null, cardID: "", job: {}, view: null, lang: 'est', team: {}})
        } else if (this.state.modal === "finish") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") this.setState({modal: null, view: "finish"})
        } else if (this.state.modal === "cancel") {
            if (e.target.className === "no") this.setState({modal: null})
            else if (e.target.className === "yes") {
                this.socket.emit("cancel_job", {cardID: this.state.cardID, job: this.state.job._id, isTeam: this.state.team.name ? true : false})
                this.setState({modal: null, cardID: "", job: {}, view: null, team: {}, lang: 'est'})
            }
        }
    }

    exitError = e => {
        e.preventDefault()
        this.setState({cardID: "", view: null, lang: 'est', errorCode: 0, team: {}, printList: {employee: null, list: null, number: null}})
    }

    finish = e => {
        e.preventDefault()
        if (this.state.loading) return
        this.setState({loading: true})
        if (this.state.job.type === "processor") this.socket.emit("finish_job", {finishJob: this.state.finishJob, jobID: this.state.job._id, cardID: this.state.cardID})
        if (this.state.job.type === "production") this.socket.emit("finish_job", {finishJob: this.state.finishJob, jobID: this.state.job._id, cardID: this.state.cardID, isTeam: this.state.team.name ? true : false})
    }

    continueJob = e => {
        e.preventDefault()
        this.setState({modal: null, cardID: "", job: {}, view: null, team: {}, lang: 'est'})
    }

    back = e => {
        e.preventDefault()
        this.setState({view: "job"})
    }

    onChangeFinishJob = e => {
        e.preventDefault()

        if (e.target.className === "hourly") {
            let hours = e.target.value.replace(/,/g, '.')
            this.setState({finishJob: {...this.state.finishJob, hours}})
        }
        else if (e.target.className === "comment") this.setState({finishJob: {...this.state.finishJob, comment: e.target.value}})
        else if (e.target.className === "restcomment") this.setState({finishJob: {...this.state.finishJob, restComment: e.target.value}})
        else {
            let value = e.target.value.replace(/,/g, '.')
            this.setState({finishJob: {...this.state.finishJob, productCount: {...this.state.finishJob.productCount, [e.target.id]: value}}})
        }
    }

    onChange = e => {
        e.preventDefault()
        this.setState({search: e.target.value})
    }

    addToTeam = e => {
        e.preventDefault()
        this.setState({team: {...this.state.team, workers: [...this.state.team.workers, e.target.id]}})
    }

    removeFromTeam = e => {
        e.preventDefault()
        let workers = this.state.team.workers.filter(x => x !== e.target.id)
        this.setState({team: {...this.state.team, workers}})
    }

    confirmTeam = e => {
        e.preventDefault()
        if (this.state.loading) return
        this.setState({loading: true})
        const date = moment().format("YYYY-MM-DD")
        const time = moment().format("HH:mm:ss")
        this.socket.emit("enterWorkspace", {cardID: this.state.cardID, date, time, team: this.state.team.workers})
    }

    reprint = e => {
        e.preventDefault()

        if (this.state.printList?.number) {
            if (e.target.className === "no") {
                this.setState({printList: {...this.state.printList, number: null}})
            }
            if (e.target.className === "yes") {
                // let q = `
                //     ^XA
                //     ^FO75,25^AVN,420,300^FD${this.state.printList.number}
                //     ^FS
                //     ^XZ`
                // this.zebraPrinter.send(q, undefined, err => console.log(err))
                // this.zebraPrinter.send(q, undefined, err => console.log(err))
                // q = `
                //     ^XA
                //     ^FO75,50^BY6,3,10
                //     ^B3N,N,250,Y,N^FD${this.state.printList.number}^FS
                //     ^XZ`
                // this.zebraPrinter.send(q, undefined, err => console.log(err))
                // this.zebraPrinter.send(q, undefined, err => console.log(err))
                let q = `^XA
                    ^FO75,10^BY6,3,10
                    ^B3N,N,220,N,N^FD${this.state.printList.number}^FS
                    ^FO80,210
                    ^AVN,200,250^FD${this.state.printList.number}
                    ^XZ`
                this.zebraPrinter.send(q, undefined, err => console.log(err))
                this.zebraPrinter.send(q, undefined, err => console.log(err))
                
                this.setState({cardID: "", view: null, lang: 'est', errorCode: 0, team: {}, printList: {employee: null, list: null, number: null}})
            }
        } else {
            this.setState({printList: {...this.state.printList, number: e.target.id}})
        }
    }

    changeLang = e => {
        e.preventDefault()
        this.setState({lang: e.target.id})
    }

    render() {
        let {workers, silt, pakend, alus, pm, type, duedate, product, per, progress, comments, ongoing, tas, employeeID} = this.state.job
        let {modal, employees, view, stock, errorCode, teams, team, printList, endData} = this.state
        let lang = (employees[employeeID] && this.state.job._id) ? employees[employeeID]?.[1] : this.state.lang
        return (
            <div className="workspace">
                {view === "job" && <div className="content">
                    <h1 className="heading">{ongoing ? translate("active_task", lang, {name: team?.name || employees?.[employeeID][0]}) : translate("your_task", lang, {name: team?.name || employees?.[employeeID][0]})}</h1>
                    {team?.name && <div className="langbuttons">
                        <a className={`langbuttons-est${this.state.lang === "est" ? " active" : ""}`} id="est" onClick={this.changeLang}>EST</a>
                        <a className={`langbuttons-rus${this.state.lang === "rus" ? " active" : ""}`} id="rus" onClick={this.changeLang}>RUS</a>
                    </div>}
                    {type === "processor" && <div className="job-content">
                        <h1 className="job-heading">{translate("task_subheading", lang)}</h1>
                        <div className="job-content-wrapper">
                            <div className="job-content-column">
                                <h1 className="job-item">{translate("product", lang)}</h1>
                                {Object.keys(product || {}).map(key => {
                                    if (product[key] === "side") return
                                    return <p key={key}><span>{progress?.ovr?.[key] ? product[key]-progress.ovr[key] : product[key]}x</span> {stock[key].name}</p>
                                })}
                                <h1 className="job-item">{translate("sideproduct", lang)}</h1>
                                {Object.keys(product || {}).map(key => {
                                    if (product[key] !== "side") return
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                            <div className="job-content-column">
                                <h1 className="job-item">{translate("duedate", lang)}</h1>
                                <p>{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                                <h1 className="job-item">{translate("alus", lang)}</h1>
                                {(alus || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">{translate("pm", lang)}</h1>
                                {(pm || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                            <div className="job-content-column">
                                <h1 className="job-item">{translate("task_workers", lang)}</h1>
                                {(workers || []).map(key => {
                                    return <p key={key}>{employees[key][0]}</p>
                                })}
                                <h1 className="job-item">{translate("tas", lang)}</h1>
                                <p>{tas === "0" ? translate("tas_0", lang) : tas === "1" ? translate("tas_0", lang) : ""}</p>
                                <h1 className="job-item">{translate("comments", lang)}</h1>
                                <p>{comments}</p>
                            </div>
                        </div>
                    </div> ||
                    type === "production" &&
                    <div className="job-content1">
                        <h1 className="job-heading">{translate("task_subheading", team?.name ? this.state.lang : lang)}</h1>
                        <div className="job-content-wrapper">
                            <div className="job-content1-column">
                                <h1 className="job-item">{translate("product", lang)}</h1>
                                    {Object.keys(product || {}).map(key => {
                                        if (product[key] === "side") return
                                        return <p key={key}><span>{per}x</span> {stock[key].name}</p>
                                    })}
                                <h1 className="job-item">{translate("duedate", lang)}</h1>
                                <p>{moment(duedate, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                                <h1 className="job-item">{translate("tas", lang)}</h1>
                                <p>{tas === "0" ? translate("tas_0", lang) : tas === "1" ? translate("tas_1", lang) : ""}</p>
                                <h1 className="job-item">{translate("comments", lang)}</h1>
                                <p>{comments}</p>
                            </div>
                            <div className="job-content1-column">
                                <h1 className="job-item">{translate("silt", lang)}</h1>
                                {(silt || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">{translate("pakend", lang)}</h1>
                                {(pakend || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">{translate("alus", lang)}</h1>
                                {(alus || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                                <h1 className="job-item">{translate("pm", lang)}</h1>
                                {(pm || []).map(key => {
                                    return <p key={key}>{stock[key].name}</p>
                                })}
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src={`${process.env.REACT_APP_SERVER_URL}/img/${this.state.job.silt[0]+this.state.job.ext}`} />
                        </div>
                    </div>}
                    <div className="buttons">
                        <div className="accept-job" onClick={ongoing ? this.toggleModal : this.accept}>
                            <i className="fas fa-arrow-right"/>
                            <h1>{ongoing ? translate("task_finish", lang) : translate("task_agree", lang)}</h1>
                        </div>
                        {(ongoing && this.state.job.type === "production") && <div className="cancel-job" onClick={this.toggleModal}>
                            <i className="fas fa-times"/>
                            <h1>{translate("task_cancel", lang)}</h1>
                        </div>}
                        <div className="decline-job" onClick={ongoing ? this.continueJob : this.toggleModal}>
                            <i className="fas fa-arrow-left"/>
                            <h1>{ongoing ? translate("task_continue", lang) : translate("task_exit", lang)}</h1>
                        </div>
                    </div>
                    {modal && <div className="confirm-modal">
                        <div className="confirm-modal-container">
                            <h1 className="text">{modal === "decline" ? translate("confirm_exit", lang) : modal === "finish" ? translate("confirm_leader", lang) : modal === "cancel" ? translate("confirm_cancel", lang) : ""}</h1>
                            <div className="confirmation-buttons">
                                <div className="yes" onClick={this.confirmation}>
                                    <h1>{translate("yes", lang)}</h1>
                                </div>
                                <div className="no" onClick={this.confirmation}>
                                    <h1>{translate("no", lang)}</h1>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> ||
                view === "finish" && <div className="finish">
                    <h1 className="heading">{translate("task_summary", lang)}</h1>
                    <div className="finish-content">
                        {type === "processor" ? <>
                        <h1 className="finish-heading">{translate("hourly_heading", lang)}</h1>
                        <div className="right">
                            <h1 className="block-heading">{translate("tas_0", lang)}:</h1>
                            <p>{translate("task_length", lang)}</p>
                            <input className="hourly" type="number" onChange={this.onChangeFinishJob} />
                            <p>{translate("hourly_comment", lang)}</p>
                            <textarea className="comment" onChange={this.onChangeFinishJob} />
                        </div>
                        <div className="left">
                            <h1 className="block-heading">{translate("product", lang)}:</h1>
                            {Object.keys(product).map(key => {
                                return <span className="product" key={key}>
                                    <p>{stock[key].name}</p>
                                    <input id={key} type="number" onChange={this.onChangeFinishJob} />
                                    <p>{product[key] === "side" ? "" : `/${Number(product[key])-Number(progress.ovr?.[key] || 0)}`}</p>
                                </span>
                            })}
                        </div>
                        </> : <>
                        <div className="right">
                            <h1 className="block-heading">{translate("tas_0", lang)}:</h1>
                            <p>{translate("task_length", lang)}</p>
                            <input className="hourly" type="number" onChange={this.onChangeFinishJob} />
                            <p>{translate("hourly_comment", lang)}</p>
                            <textarea className="comment" onChange={this.onChangeFinishJob} />
                        </div>
                        <div className="left">
                            <h1 className="block-heading">{translate("task_notes", lang)}:</h1>
                            <textarea className="restcomment" onChange={this.onChangeFinishJob} />
                        </div>
                        </>}
                    </div>
                    <div className="backbutton" onClick={this.back}>
                        <i className="fas fa-arrow-left"/>
                    </div>
                    <div className="finish-job" onClick={this.finish}>
                        <i className="fas fa-arrow-right"/>
                        <h1>{translate("task_finish", lang)}</h1>
                    </div>
                </div> ||
                view === "team" && <div className="team">
                    <h1 className="team-heading">{this.state.team.name}</h1>
                    <div className="langbuttons">
                        <a className={`langbuttons-est${this.state.lang === "est" ? " active" : ""}`} id="est" onClick={this.changeLang}>EST</a>
                        <a className={`langbuttons-rus${this.state.lang === "rus" ? " active" : ""}`} id="rus" onClick={this.changeLang}>RUS</a>
                    </div>
                    <div className="team-workers">
                        <h1 className="subheading">{translate("team_members", lang)}</h1>
                        <div className="worker-list">
                            {this.state.team.workers.map(worker => {
                                return <div key={worker}>
                                    <span>{this.state.employees[worker][0]}</span>
                                    <a id={worker} onClick={this.removeFromTeam}>&times;</a>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="all-workers">
                        <input value={this.state.search} onChange={this.onChange}/>
                        <div>
                            {this.state.allWorkers.map(worker => {
                                if (this.state.team.workers.includes(worker)) return
                                let name = this.state.employees[worker][0]
                                if (this.state.search && !name.toLowerCase().includes(this.state.search.toLowerCase())) return
                                return <span key={worker} id={worker} onClick={this.addToTeam}>{name}</span>
                            })}
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="continue" onClick={this.confirmTeam}>
                            <i className="fas fa-arrow-right"/>
                            <h1>{translate("team_confirm", lang)}</h1>
                        </div>
                        <div className="exit" onClick={this.continueJob}>
                            <i className="fas fa-arrow-left"/>
                            <h1>{translate("task_exit", lang)}</h1>
                        </div>
                    </div>
                </div> ||
                view === "print" && <div className="print">
                    <div className="backbutton" onClick={this.exitError}>
                        <i className="fas fa-arrow-left"/>
                    </div>
                    <div className="langbuttons langbuttons-right">
                        <a className={`langbuttons-est${this.state.lang === "est" ? " active" : ""}`} id="est" onClick={this.changeLang}>EST</a>
                        <a className={`langbuttons-rus${this.state.lang === "rus" ? " active" : ""}`} id="rus" onClick={this.changeLang}>RUS</a>
                    </div>
                    {printList.employee ? <>
                        <h1 className="heading">{teams[printList.employee]?.name || employees[printList.employee][0]}</h1>
                        <div className="print-list">
                            <div className="print-list-item">
                                <h1 className="p-name">{translate("product_name", lang)}</h1>
                                <h1 className="p-time">{translate("created_at", lang)}</h1>
                                <h1 className="p-nr">{translate("alus_nr", lang)}</h1>
                            </div>
                            {(printList.list || []).map(item => {
                                return <div className="print-list-item" key={item._id}>
                                    <h1 className="p-name">{stock[item.article].name}</h1>
                                    <h1 className="p-time">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</h1>
                                    <h1 className="p-nr">{item.restNr}</h1>
                                    <h1 className="p-btn" id={item.restNr} onClick={this.reprint}>{translate("print", lang)}</h1>
                                </div>
                            })}
                        </div>
                    </> :
                    <h1 className="print-message">{translate("reprint", lang)}</h1>}
                    {printList.number && <div className="confirm-modal">
                        <div className="confirm-modal-container modal-large">
                            <h1 className="text">{translate("reprint_confirm", lang, {number: printList.number})}</h1>
                            <div className="confirmation-buttons">
                                <div className="yes" onClick={this.reprint}>
                                    <h1>{translate("yes", lang)}</h1>
                                </div>
                                <div className="no" onClick={this.reprint}>
                                    <h1>{translate("no", lang)}</h1>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> ||
                view === "end" && <div className="end">
                    <h1 className="heading">{endData.name}</h1>
                    <h1 className="end-message">{translate("task_result", lang, {number: endData.restNr})}</h1>
                    <div className="backbutton" onClick={this.exitError}>
                        <i className="fas fa-arrow-left"/>
                        <h1>{translate("task_exit", lang)}</h1>
                    </div>
                </div> ||
                view === "error" && <div className="error">
                    <h1 className="heading">{team?.name || employees?.[employeeID]?.[0]}</h1>
                    <div className="langbuttons langbuttons-right">
                        <a className={`langbuttons-est${this.state.lang === "est" ? " active" : ""}`} id="est" onClick={this.changeLang}>EST</a>
                        <a className={`langbuttons-rus${this.state.lang === "rus" ? " active" : ""}`} id="rus" onClick={this.changeLang}>RUS</a>
                    </div>
                    <h1 className="error-message">{errorCode === 1 ? translate("error_system", lang) : errorCode === 2 ? translate("error_nochip", lang) : errorCode === 3 ? translate("error_invalid", lang) : errorCode === 4 ? translate("error_checkin", lang) : errorCode === 5 ? translate("error_task", lang) : errorCode === 6 ? translate("error_team", lang) : translate("error_code", lang, {number: "42"})}</h1>
                    <div className="backbutton" onClick={this.exitError}>
                        <i className="fas fa-arrow-left"/>
                        <h1>{translate("task_exit", lang)}</h1>
                    </div>
                </div>
                ||
                <div className="default">

                </div>}
                {this.state.loading && <div className="loader">
                    <div className="lds-ring"><div/><div/><div/><div/></div>
                </div>}
            </div>
        )
    }
}

export default Workspace