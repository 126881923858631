import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import moment from 'moment'
import './scss/index.scss'
import AppRouter from './routers/AppRouter'
import configureStore from './redux/store/store'

export const store = configureStore

moment.updateLocale('en', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});

const jsx = (
  <Provider store={store}>
      <AppRouter/>
  </Provider>
)

ReactDOM.render(jsx, document.getElementById('app'))